import Uppy from '@uppy/core'
import UploadContext, { FileAdder } from 'app/contexts/upload'
import { useCallback } from 'react';

const fetchImageFromUri = async (uri: any) => {
  const response = await fetch(uri);
  const blob = await response.blob();
  return blob;
};

export const UploadProvider = ({ children, uppy }: { children: React.ReactNode, uppy: Uppy | null | undefined }) => {
  const addFile = useCallback<FileAdder>(async (asset) => {
    if(!uppy) return null;

    const blob = await fetchImageFromUri(asset.uri);

    function doAdd(uppy: Uppy) {
      return uppy.addFile({
        name: asset.fileName || undefined,
        data: blob,
        type: asset.type,
        meta: {
          uri: asset.uri,
        }
      });
    }

    try {
      return doAdd(uppy);
    } catch (e: any) {
      if(e.message.includes("duplicate file")) {
        uppy.cancelAll();
        return doAdd(uppy);
      } else {
        throw e;
      }
    }
  }, [uppy])

  return (
    <UploadContext.Provider value={{uppy, addFile}}>
      {children}
    </UploadContext.Provider>
  )
}
