import { createSlice } from '@reduxjs/toolkit'

export type SubscriptionsCountType = {
  channel: string;
  count: number;
  priority: boolean;
}

export type CountsState = SubscriptionsCountType[];

export type SubscriptionsSliceState = {
  subscribers: CountsState;
}

const initialState: SubscriptionsSliceState = {
  subscribers: [],
};

const subscriberSlice = createSlice({
  name: 'subscriptions',
  initialState,
  reducers: {
    reset: () => initialState,
    subscriberAdded(state, reduxAction) {
      const { channelTopic, priority } = reduxAction.payload;
      let countState = { channel: channelTopic, count: 0, priority };

      const existingIndex = state.subscribers.findIndex((s) => s.channel == channelTopic);
      if (existingIndex >= 0) {
        countState = state.subscribers.splice(existingIndex, 1)[0] as SubscriptionsCountType;
      }

      countState.count += 1;
      state.subscribers.push(countState);
      return state;
    },
    subscriberRemoved(state, reduxAction) {
      const { channelTopic } = reduxAction.payload;
      const existingCount = state.subscribers.find((s) => s.channel == channelTopic);
      if (existingCount || existingCount == 0) {
        // Allow this going below zero to accomodate for
        // async calls to add and remove subscribers
        existingCount.count -= 1;
      }
      return state;
    },
  }
})

export const { subscriberAdded, subscriberRemoved, reset } = subscriberSlice.actions
export default subscriberSlice.reducer

