import 'raf/polyfill'
import 'setimmediate'

import Provider from 'app/provider'
import Head from 'next/head'
import React from 'react'
import Chrome from 'app/navigation/web'

import '../public/fonts/Gimlet/gimlet.css'
import '../public/fonts/Inter/inter.css'
import '../global.css'
import { AppProps } from 'next/app'

function MyApp({ Component, pageProps }: AppProps) {
  return (
    <>
      <Head>
        <title>Oase</title>
        <meta
          name="description"
          content="Where communities thrive"
        />
        <link rel="icon" href="/favicon.ico" />
        <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
      </Head>
      <Provider>
        <Chrome>
          <Component {...pageProps} />
        </Chrome>
      </Provider>
    </>
  )
}

export default MyApp
