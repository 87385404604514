import { useState, useEffect, ReactNode, useContext, useRef, useCallback } from 'react'
import { AppState } from 'react-native'
import PropTypes from 'prop-types'
import { Socket, Channel } from 'phoenix'
import { refreshAccessToken } from 'app/lib/mainframeFetch'
import { AppDispatch } from 'app/provider/configureStore'

import SocketContext from 'app/contexts/socket'
import TokenContext from 'app/contexts/token'

// import { captureMessage } from 'app/lib/Sentry';

import { messageReceived, ReplaceType, pullProjection, replaceProjection, setConnectionStatus, joining, joined } from 'app/slices/projections'
import { SubscriptionsSliceState } from 'app/slices/subscriptions'
import { useDispatch, useSelector } from 'react-redux'

import { Decrypter, useEncryption } from 'app/hooks/useEncryption'
import { isDevelopment } from 'app/lib/config'

type ChannelJoinResponseFromMainframe = {
  reason: "not found" | "not allowed" | "no session";
}
type ErrorContext = {
  channel?: Channel;
  socket?: Socket;
  situation: 'join' | 'message' | 'connect';
};
type ErrorCallback = (context: ErrorContext, error: ChannelJoinResponseFromMainframe | Event | string | number) => void;

type SocketProviderProps = {
  wsUrl: string;
  children: ReactNode;
};

const SocketProvider = ({ wsUrl, children }: SocketProviderProps) => {
  const channelsRef = useRef<{ [key: string]: any }>({});
  const { decrypt } = useEncryption();
  const [socket, setSocket] = useState<any>()
  const [appIsInForeground, setAppIsInForeground] = useState(true);
  const { token, setToken } = useContext(TokenContext);
  const dispatch = useDispatch();
  const subscriberCounts = useSelector((state: { subscriptions: SubscriptionsSliceState }) => state.subscriptions.subscribers)

  useEffect(() => {
    const subscription = AppState.addEventListener('change', nextAppState => {
      if (nextAppState == "active") {
        setAppIsInForeground(true);
      } else if (nextAppState == "background") {
        setAppIsInForeground(false);
      }
    });

    return () => {
      subscription.remove();
    };
  }, [setAppIsInForeground]);

  const onError = useCallback<ErrorCallback>(async (context, error) => {
    if ((error as ChannelJoinResponseFromMainframe).reason == "no session") {
      const newToken = await refreshAccessToken();
      if (newToken) {
        setToken(newToken);
      }
    } else {
      if(isDevelopment()) {
        if(context.socket) {
          console.error("🔥 Socket error", JSON.stringify(error, null, 2));
        }
      }
      // captureMessage(JSON.stringify(error));
    }
  }, [setToken]);

  useEffect(() => {
    let newSocket: any = null;
    if (token && appIsInForeground) {
      newSocket = new Socket(wsUrl, { params: { access_token: token } })
      dispatch(setConnectionStatus({ status: "connecting" }));
      newSocket.onError(function(error: Event | string | number) {
        onError({socket: newSocket, situation: 'connect'}, error);
      })
      newSocket.onOpen(function() {
        // console.log("🎤 Socket opened");
        setSocket(newSocket);
        dispatch(setConnectionStatus({ status: "connected" }));
      })
      newSocket.onClose(function() {
        // console.log("🚪 Socket closed");
      });
      newSocket.connect();
    }
    return () => {
      if (newSocket) {
        setSocket(null);
        newSocket.disconnect();
        channelsRef.current = {};
      }
    };
  }, [wsUrl, token, onError, appIsInForeground]);

  useEffect(() => {
    if (!socket) return;
    if (!dispatch) return;

    function sync(runForPriority: boolean) {
      return subscriberCounts.map(function(subscriberCount, _index) {
        new Promise((resolve, _reject) => {
          const count = subscriberCount.count;
          const channelTopic = subscriberCount.channel;
          const priority = subscriberCount.priority;
          if (priority != runForPriority) return resolve("OK");

          if (count && count > 0 && !channelsRef.current[channelTopic]) {
            channelsRef.current[channelTopic] = joinChannel(socket, channelTopic, dispatch, decrypt, onError);
          } else if (count === 0 && channelsRef.current[channelTopic]) {
            dispatch(joining({ channelTopic }));
            channelsRef.current[channelTopic]?.leave();
            delete channelsRef.current[channelTopic];
          }
          resolve("OK");
        });
      });
    }

    // console.log("🔌 Syncing channels");
    Promise.all(sync(true)).then(() => sync(false));
  }, [subscriberCounts, socket, dispatch, onError]);

  return (
    <SocketContext.Provider value={{ socket, channels: channelsRef }}>
      {children}
    </SocketContext.Provider>
  )
}

const joinChannel = (socket: any, channelTopic: string, dispatch: AppDispatch, decrypt: Decrypter, onError: ErrorCallback) => {
  if (!socket) return () => { };

  const channel = socket.channel(channelTopic, { client: 'browser' });

  channel.onMessage = (incommingEvent: string, incommingPayload: any) => {
    let event = incommingEvent;
    let payload = incommingPayload;

    if (incommingEvent === 'phx_reply') {
      event = `projection.do.${incommingPayload.status}`;
      payload = incommingPayload.response;
    }

    switch (event) {
      case 'projection.ask.replace':
        dispatch(replaceProjection({ event, payload, channelTopic, channel }));
        break;

      case 'projection.ask.pull':
        dispatch(pullProjection({ event, payload, channelTopic, channel }));
        break;

      case 'projection.do.replace':
      case 'projection.do.head':
      case 'projection.do.patch':
      case 'projection.do.tail':
        dispatch(messageReceived({ event, payload, channelTopic, decrypt }));
        break;

      case 'phx_error':
        onError({ channel, situation: 'message' }, `Channel message error: ${JSON.stringify(incommingEvent)} ${JSON.stringify(incommingPayload)}`);
        break;

      default:
        break;
    };

    // We must do this to prevent the channel from closing
    return incommingPayload;
  }

  // channel.onClose(() => console.log(`🚪 Channel closed ${channelTopic}`));

  dispatch(joining({ channelTopic }));
  channel.join()
    .receive("ok", (res: ReplaceType) => {
      // console.log(`🎤 Channel joined ${channelTopic}`);
      if (res.item || res.collection) {
        dispatch(messageReceived({ event: 'projection.do.join', payload: res, channelTopic, decrypt }));
      }

      // 2023-08-23: I commented this out, as it seems weird to ask for a replace, when we already have the fresh collection
      // 2023-08-31: We actually need that replace, because the collection might have changed in the older parts, that
      // might not be in the new collection from the join.
      if ((!res.item && !res.collection) || res.has_more) {
        // If we got a collection from the join, we need to ask for a replace
        // or
        // if we got nothing.
        // I.e. if there is no item, we need to ask for a replace
        dispatch(replaceProjection({ channelTopic, channel }));
      }
      dispatch(joined({ channelTopic }));

      res
    })
    .receive("error", (err: any) => {
      onError({ channel, situation: 'join' }, err);
    })

  return channel;
}

SocketProvider.defaultProps = {
}

SocketProvider.propTypes = {
  wsUrl: PropTypes.string.isRequired,
}

export default SocketProvider
