import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { Decrypter, recursivelyDecrypt } from 'app/hooks/useEncryption'
import { Channel } from 'phoenix';

type BaseActionType = {
  version?: string;
  item?: any;
  collection?: any[];
}

export type PatchType = BaseActionType & {
  event: "patch";
  collection: any[];
}

export type ActionWithChannel = {
  channel: Channel;
};

export type ReplaceType = BaseActionType & {
  event: "replace";
  item?: any;
  collection?: any[];
  new_head?: string;
  new_tail?: string;
  has_more?: boolean;
}

export type JoinType = BaseActionType & {
  event: "join";
  item?: any;
  collection?: any[];
  new_head?: string;
  new_tail?: string;
  has_more?: boolean;
}

export type PullType = BaseActionType & {
  event: "pull";
  collection: any[];
  fits_on_head: string;
  new_head: string;
}

export type MoreType = BaseActionType & {
  event: "more";
  collection: any[];
  fits_on_tail: string;
  new_tail: string;
  has_more: boolean;
}

export type PayloadType = JoinType | PatchType | ReplaceType | PullType | MoreType;

export type ProjectionDataType<T> = {
  item?: T;
  collection?: T[];
  head?: string;
  tail?: string;
  has_more?: boolean;
  loading?: boolean;
  connecting?: boolean;
  version?: string;
}

type DataState = {
  [key: string]: ProjectionDataType<any>;
}

export type ProjectionSliceState = {
  connectionStatus: ConnectionStatus;
  data: DataState;
  sessions: DataState;
}

export type ConnectionStatus = "disconnected" | "connecting" | "connected";

const initialState: ProjectionSliceState = {
  connectionStatus: "disconnected",
  data: {
  },
  sessions: {
  },
};

export const messageReceived = createAsyncThunk(
  'projections/messageReceived',
  async (payload: { event: string, payload: PayloadType, channelTopic: string, decrypt: Decrypter }, _thunkAPI) => {
    // Go through all keys in payload.item and iterate through keys on items in payload.collection
    // For each key that ends with _cipher_bundle
    // - decrypt it
    // - add the decrypted value to the item without the _cipher_bundle suffix

    let decryptedPayload = Object.assign({}, payload.payload);
    const decrypt = payload.decrypt;

    decryptedPayload.item = await recursivelyDecrypt(decryptedPayload.item, decrypt);
    decryptedPayload.collection = await recursivelyDecrypt(decryptedPayload.collection, decrypt);

    // console.log(`📡 ${Date.now()} ${payload.event} ${payload.channelTopic}`);
    return {
      payload: decryptedPayload,
      channelTopic: payload.channelTopic,
      event: payload.event,
    };
  }
);

type Optimistic = {
  projection: string;
  action: "optimistic.append" | "optimistic.replace";
  item: any;
}

export const mutate = createAsyncThunk(
  'projections/mutate',
  async (payload: { method: (path: string, body?: any) => Promise<any>, path: string, params: any, optimistic: Optimistic }, _thunkAPI) => {
    const response = await payload.method(payload.path, payload.params);
    const result = Object.assign({}, payload.optimistic.item, response);
    return result;
  }
);

function removeOptimisticFromCollection(collection: any[]) {
  if (!collection) return collection;
  if (!collection.find((item) => item.optimistic)) return collection;

  // For all elements in collection that have optimistic: true, 
  // find their potential match in the collection that has optimistic: undefined
  // For the pair
  // 1. merge the two.
  // 2. remove the one with optimistic: false
  // 3. set optimistic to false on the one with optimistic: true
  //
  // Return the collection.

  const optimistic = collection.filter((item) => item.optimistic);

  optimistic.forEach((optimisticItem) => {
    const nonOptimisticItem = collection.find((item) =>
      item.id == optimisticItem.id && !item.optimistic
    );

    const newItem = Object.assign({}, optimisticItem, nonOptimisticItem);
    newItem.optimistic = false;

    collection = collection.map((item) => {
      if (item.temp_id == newItem.temp_id) {
        return newItem;
      }
      if (item.id == newItem.id && !item.optimistic) {
        return null;
      }
      return item;
    }).filter(item => !!item);
  });

  return collection;
}

function doReplaceProjection(state: ProjectionSliceState, reduxAction: any) {
  let { channelTopic, channel } = reduxAction.payload;

  const head = state.data[channelTopic]?.head;
  const tail = state.data[channelTopic]?.tail;
  if (channel) {
    channel.push('projection.ask.replace', { head, tail });
  } else {
    throw new Error(`Channel not found for topic: ${channelTopic}`);
  }
  return state;
}

const projectionsSlice = createSlice({
  name: 'projections',
  initialState,
  reducers: {
    reset: () => initialState,
    startSession(state, reduxAction) {
      let { channelTopic } = reduxAction.payload;
      state.sessions[channelTopic] = state.data[channelTopic] || {};
      return state;
    },
    pullProjection(state, reduxAction) {
      let { channelTopic, channel } = reduxAction.payload;
      if (channel) {
        const head = state.data[channelTopic]?.head;
        channel.push('projection.ask.pull', { head });
      } else {
        throw new Error(`Channel not found for topic: ${channelTopic}`);
      }
      return state;
    },
    joining: (state, reduxAction) => {
      const { channelTopic } = reduxAction.payload;
      state.data[channelTopic] = {
        ...(state.data[channelTopic] || {}),
        connecting: true,
      };
      return state;
    },
    joined: (state, reduxAction) => {
      const { channelTopic } = reduxAction.payload;
      (state.data[channelTopic] || {}).connecting = false;
      return state;
    },
    replaceProjection(state, reduxAction) {
      doReplaceProjection(state, reduxAction);
      const { channelTopic } = reduxAction.payload;
      (state.data[channelTopic] || {}).loading = true;
      return state;
    },
    setConnectionStatus(state, reduxAction) {
      const { status } = reduxAction.payload;
      state.connectionStatus = status;
      if (status != "connected") {
        Object.keys(state.data).forEach((key) => {
          // @ts-ignore
          state.data[key].connecting = true;
        });
      }
      return state;
    },
    getMore(state, reduxAction) {
      const { channelTopic, channel } = reduxAction.payload;
      const data = state.data[channelTopic];
      const tail = data?.tail;

      if (data?.has_more == false) return state;

      if (channel) {
        channel.push('projection.ask.more', { tail });
        (state.data[channelTopic] || {}).loading = true;
      } else {
        throw new Error(`Channel not found for topic: ${channelTopic}`);
      }
      return state;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(mutate.pending, (state, reduxAction) => {
      const { optimistic } = reduxAction.meta.arg;
      const item = optimistic.item;
      switch (optimistic.action) {
        case "optimistic.append":
          const s = state.data[optimistic.projection] || {};
          const newCollection = [item].concat(s?.collection || []);
          state.data[optimistic.projection] = {
            collection: newCollection,
            has_more: s.has_more,
            head: s?.head,
            item: s?.item,
            tail: s?.tail,
            version: s?.version,
          };
          return state;
      }

      return state;
    }),
      builder.addCase(mutate.fulfilled, (state, reduxAction) => {
        const { optimistic } = reduxAction.meta.arg;
        const item = reduxAction.payload;
        switch (optimistic.action) {
          case "optimistic.append":
            const s = state.data[optimistic.projection] || {};
            const newCollection = s?.collection?.map((i: any) => {
              if (i.temp_id == item.temp_id) {
                return item;
              } else {
                return i;
              }
            });
            state.data[optimistic.projection] = {
              collection: newCollection,
              has_more: s.has_more,
              head: s?.head,
              item: s?.item,
              tail: s?.tail,
              version: s?.version,
            };
            return state;
        }

        return state;
      }),
      builder.addCase(mutate.rejected, (state, reduxAction) => {
        const { optimistic } = reduxAction.meta.arg;
        switch (optimistic.action) {
          case "optimistic.append":
            const s = state.data[optimistic.projection] || {};
            const newCollection = s?.collection?.filter((item: any) => item.temp_id != optimistic.item.temp_id);
            state.data[optimistic.projection] = {
              collection: newCollection,
              has_more: s.has_more,
              head: s?.head,
              item: s?.item,
              tail: s?.tail,
              version: s?.version,
            };
            return state;
        }

        return state;
      }),
      builder.addCase(messageReceived.fulfilled, (state, reduxAction: { payload: { event: string, payload: PayloadType, channelTopic: string } }) => {
        const { payload, channelTopic } = reduxAction.payload;
        if (!payload) return state;

        state.data[channelTopic] = state.data[channelTopic] || {};
        // @ts-ignore
        state.data[channelTopic].loading = false;

        if (payload.event == "patch") {
          // The semantics:
          // When a patch is received, we need to update the collection
          // The payload will have a collection, and for each element in that,
          // we need to find the element in the collection and update it.
          // If the element is not found, do nothing.

          const currentCollection = state.data[channelTopic]?.collection || [];
          const newCollection = currentCollection.map((item: any) => {
            const found = payload.collection.find((patch) => patch.id == item.id);
            return found || item
          });

          state.data[channelTopic] = Object.assign({}, state.data[channelTopic] || {}, { collection: newCollection })
        } else if (payload.event == "more") {
          const s = state.data[channelTopic] || {};
          if (s.tail != payload.fits_on_tail) return state;
          // @ts-ignore
          const newCollection = removeOptimisticFromCollection(s?.collection?.concat(payload.collection));
          state.data[channelTopic] = {
            collection: newCollection,
            has_more: payload.has_more,
            head: s?.head,
            item: s?.item,
            tail: payload.new_tail,
            version: payload.version,
          };
        } else if (payload.event == "pull") {
          const s = state.data[channelTopic] || {};
          if (s.head != payload.fits_on_head) return state;
          if (payload.new_head && s.head == payload.new_head) {
            return state;
          }
          const newCollection = removeOptimisticFromCollection(
            payload?.collection?.concat(s?.collection || [])
          );
          state.data[channelTopic] = {
            collection: newCollection,
            has_more: s.has_more,
            head: payload?.new_head,
            item: s?.item,
            tail: s?.tail,
            version: payload.version,
          };
        } else if (payload.event == "join") {
          if (payload.item) {
            if (payload.version && (state.data[channelTopic]?.version == payload.version)) {
              return state;
            }
            state.data[channelTopic] = {
              collection: payload.collection,
              has_more: payload.has_more,
              head: payload.new_head,
              item: payload.item,
              tail: payload.new_tail,
              version: payload.version,
            };
          }
          if (payload.collection) {
            const s = state.data[channelTopic] || {};
            if (payload.has_more && payload.new_head && s.head == payload.new_head) {
              // We will replace the collection anyway, so no need to do anything
              // Make sure the above logic matches the replace logic in the socket:
              // packages/app/provider/socket/index.tsx:184
              return state;
            }

            let newCollection: any = payload.collection;
            if (Array.isArray(payload.collection) && payload.has_more) {
              // Our current collection may have more items than the join payload.
              // So let's take all the items form the current collection,
              // untill the last item in the join payload is found.
              // Then concat the join payload to the current collection.
              const lastItemInJoinPayload = payload.collection[payload.collection.length - 1];
              const indexOfLastItemInJoinPayloadInWhatWeHave = (s.collection || []).findIndex((item: any) => {
                if (item.id) {
                  return item.id == lastItemInJoinPayload.id;
                } else {
                  return item == lastItemInJoinPayload;
                }
              });
              const whatWeHave = (s.collection || []).slice(indexOfLastItemInJoinPayloadInWhatWeHave + 1, s.collection?.length);
              newCollection = payload.collection.concat(whatWeHave);
            }

            state.data[channelTopic] = {
              collection: newCollection,
              has_more: payload.has_more,
              head: payload.new_head,
              item: payload.item,
              tail: s.tail || payload.new_tail,
              version: payload.version,
            };
          }

          // Make sure to update sessions
          if (state.sessions[channelTopic]) {
            state.sessions[channelTopic] = state.data[channelTopic] || {};
          }

        } else if (payload.item || payload.collection) {
          if (payload.version && state.data[channelTopic]?.version == payload.version) {
            return state;
          }
          state.data[channelTopic] = {
            collection: payload.collection,
            has_more: payload.has_more,
            head: payload.new_head,
            item: payload.item,
            tail: payload.new_tail,
            version: payload.version,
          };
        }
        return state;
      })
  }
})

export const { pullProjection, replaceProjection, joining, joined, startSession, getMore, reset, setConnectionStatus } = projectionsSlice.actions
export default projectionsSlice.reducer
