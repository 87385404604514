import { faBullseyeArrow } from '@fortawesome/pro-solid-svg-icons/faBullseyeArrow';
import { faCheckCircle } from '@fortawesome/pro-solid-svg-icons/faCheckCircle';
import { faCoins } from '@fortawesome/pro-solid-svg-icons/faCoins';
import { faArrowUpFromBracket } from '@fortawesome/pro-solid-svg-icons/faArrowUpFromBracket';
import { faArrowDown } from '@fortawesome/pro-solid-svg-icons/faArrowDown';
import { faMoneyBillSimpleWave } from '@fortawesome/pro-solid-svg-icons/faMoneyBillSimpleWave';
import { faCalendarStar } from '@fortawesome/pro-solid-svg-icons/faCalendarStar';
import { faChartSimple } from '@fortawesome/pro-solid-svg-icons/faChartSimple';
import { faCheck } from '@fortawesome/pro-solid-svg-icons/faCheck';
import { faClipboard } from '@fortawesome/pro-solid-svg-icons/faClipboard';
import { faClipboardCheck } from '@fortawesome/pro-solid-svg-icons/faClipboardCheck';
import { faCog } from '@fortawesome/pro-solid-svg-icons/faCog';
import { faEnvelope } from '@fortawesome/pro-solid-svg-icons/faEnvelope';
import { faHeadSide } from '@fortawesome/pro-solid-svg-icons/faHeadSide';
import { faImages } from '@fortawesome/pro-solid-svg-icons/faImages';
import { faLightbulbOn } from '@fortawesome/pro-solid-svg-icons/faLightbulbOn';
import { faMapPin } from '@fortawesome/pro-solid-svg-icons/faMapPin';
import { faLock } from '@fortawesome/pro-solid-svg-icons/faLock';
import { faMoneyBillTransfer } from '@fortawesome/pro-solid-svg-icons/faMoneyBillTransfer';
import { faPaperPlaneTop } from '@fortawesome/pro-solid-svg-icons/faPaperPlaneTop';
import { faPeopleGroup } from '@fortawesome/pro-solid-svg-icons/faPeopleGroup';
import { faPlus } from '@fortawesome/pro-solid-svg-icons/faPlus';
import { faSeedling } from '@fortawesome/pro-solid-svg-icons/faSeedling';
import { faShieldHeart } from '@fortawesome/pro-solid-svg-icons/faShieldHeart';
import { faTreeDeciduous } from '@fortawesome/pro-solid-svg-icons/faTreeDeciduous';
import { faUser } from '@fortawesome/pro-solid-svg-icons/faUser';
import { faXmark } from '@fortawesome/pro-solid-svg-icons/faXmark';
import { faReceipt } from '@fortawesome/pro-solid-svg-icons/faReceipt';
import { faCamera } from '@fortawesome/pro-solid-svg-icons/faCamera';
import { faGif } from '@fortawesome/pro-solid-svg-icons/faGif';
import { faComments } from '@fortawesome/pro-solid-svg-icons/faComments';

export const add = (library: any) => {
  library.add(
    faCalendarStar,
    faChartSimple,
    faPeopleGroup,
    faCog,
    faUser,
    faXmark,
    faPaperPlaneTop,
    faCheck,
    faPlus,
    faEnvelope,
    faHeadSide,
    faImages,
    faMoneyBillTransfer,
    faBullseyeArrow,
    faLightbulbOn,
    faMapPin,
    faSeedling,
    faShieldHeart,
    faTreeDeciduous,
    faMoneyBillSimpleWave,
    faLock,
    faArrowDown,
    faReceipt,
    faCoins,
    faCamera,
    faGif,
    faComments,
    faClipboard,
    faClipboardCheck,
    faArrowUpFromBracket,
    faCheckCircle
  );
}
