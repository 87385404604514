import { useEffect, useCallback, useContext } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getMore, ProjectionDataType, ProjectionSliceState, startSession } from 'app/slices/projections'
import { subscriberRemoved, subscriberAdded } from 'app/slices/subscriptions'
import SocketContext from 'app/contexts/socket'

type GetMoreType = () => void;

type OptionsType = {
  priority?: boolean;
  paginate?: boolean;
  session?: boolean;
}

const useProjection = <T = any>(
  channelTopic: string,
  options: OptionsType = { priority: false, paginate: true, session: false }
): [ProjectionDataType<T> | undefined, GetMoreType] => {
  if (channelTopic.endsWith("undefined")) {
    throw new Error("Channel topic ends with undefined");
  }

  const { channels } = useContext(SocketContext);
  const defaultOptions = { priority: false, paginate: true };
  const mergedOptions = { ...defaultOptions, ...options };
  const dispatch = useDispatch();
  const projection = useSelector((state: { projections: ProjectionSliceState }) => {
    if(options?.session) {
      return state.projections.sessions[channelTopic] || { loading: true };
    } else {
      return state.projections.data[channelTopic] || { loading: true };
    }
  });

  useEffect(() => {
    if (channelTopic == "") {
      return;
    }
    if(options?.session) {
      dispatch(startSession({ channelTopic }))
    }
    dispatch(subscriberAdded({ channelTopic, priority: mergedOptions.priority }))
    return () => {
      dispatch(subscriberRemoved({ channelTopic }));
    }
  }, [channelTopic]);

  const moreCallback = useCallback(() => {
    const channel = channels.current[channelTopic];
    if (channel) {
      dispatch(getMore({ channelTopic, channel }));
    } else {
      console.error(`Channel ${channelTopic} not found in channels: ${Object.keys(channels)}}`);
    }
  }, [channelTopic]);

  return [projection, moreCallback];
};

export default useProjection
