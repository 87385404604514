// import { useContext } from 'react'
// import TokenContext from 'app/contexts/token'
import mainframeFetch from 'app/lib/mainframeFetch'

export const verbs = {
  get: async (path: string) => {
    return await mainframeFetch(path, {}).then(res => res.json());
  },
  post: async (path: string, body: any = {}) => {
    return await mainframeFetch(path, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    }).then(res => res.json());
  },
  del: async (path: string) => {
    return await mainframeFetch(path, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
      },
    }).then(res => res.json());
  },
  put: async (path: string, body: any = {}) => {
    return await mainframeFetch(path, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    }).then(res => res.json());
  }
}

export default () => verbs
