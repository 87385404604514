import { NavigationProvider } from './navigation'
import { SafeArea } from './safe-area'
import SocketProvider from './socket'
import { UploadProvider } from './upload'
import TokenProvider from './token'
import { EncryptionKeysProvider } from './encryption-keys'
import { AppBadgeProvider } from './app-badge'
import { ReaderProvider } from './reader'
import UserProvider from './user'
import MessageInteractionProvider from "./message-interaction";
import { Provider as ReduxProvider } from 'react-redux'
import { wsUrl } from 'app/lib/config'
import { useEffect, useState } from 'react'

import { addToLibrary } from "app/icon/library";

import {
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query'
// Create a client
const queryClient = new QueryClient()

import configureStore from './configureStore'
const store = configureStore;

import { configureUppy } from './configureUppy'
import Uppy from '@uppy/core'

export default function Provider({ children }: { children: React.ReactNode }) {
  const [uppyState, setUppyState] = useState<Uppy | null | undefined>();
  useEffect(() => {
    setUppyState(configureUppy(store));

    addToLibrary();
  }, []);

  return (
    <SafeArea>
      <QueryClientProvider client={queryClient}>
        <EncryptionKeysProvider>
          <ReduxProvider store={store}>
            <UploadProvider uppy={uppyState}>
              <TokenProvider>
                <NavigationProvider>
                  <UserProvider>
                    <SocketProvider wsUrl={wsUrl()}>
                      <ReaderProvider>
                        <AppBadgeProvider>
                          <MessageInteractionProvider>
                            {children}
                          </MessageInteractionProvider>
                        </AppBadgeProvider>
                      </ReaderProvider>
                    </SocketProvider>
                  </UserProvider>
                </NavigationProvider>
              </TokenProvider>
            </UploadProvider>
          </ReduxProvider>
        </EncryptionKeysProvider>
      </QueryClientProvider>
    </SafeArea>
  )
}
