import { useCallback } from 'react';
import { Buffer } from 'buffer';
import useEncryptionKeys, { KeyGetter } from 'app/hooks/useEncryptionKeys';
import systemDecrypt from 'app/lib/decrypt';

export type CipherBundle = {
  oaseId: string;
  alg: 'AES-GCM',
  kid: string;
  ivBase64: string;
  cipherBase64: string;
  key_url?: string;
  proof_jwt?: string;
}

export type Decrypter = (cipherBundle: CipherBundle) => Promise<string>;
export type Encrypter = (clearText: string, oaseId?: string) => Promise<CipherBundle>

type ReturnType = {
  encrypt: Encrypter;
  decrypt: Decrypter;
}

export const recursivelyDecrypt = async (item: any, decrypt: Decrypter): Promise<any> => {
  // If an array, recursively decrypt each item
  if (Array.isArray(item)) {
    const all = item.map(async (i) => await recursivelyDecrypt(i, decrypt));
    const result = await Promise.all(all);
    return result;
  }

  // If an object:
  // - if it has a _cipher_bundle property, decrypt it
  // - all other properties, recursively decrypt
  if (typeof item === 'object' && item) {
    const all = Object.keys(item).map(async (key) => {
      if (key.endsWith('_cipher_bundle') && item[key]) {
        const bundle = item[key];
        let decryptedValue = "";
        try {
          decryptedValue = await decrypt(bundle);
        } catch (e: any) {
          if (e.name === 'OperationError') {
            // This is a known error that happens when the encrypted thing is the empty string
          } else {
            console.error('error', e);
          }
        }
        const new_key = key.replace('_cipher_bundle', '');
        if (new_key.endsWith('_object') && decryptedValue) {
          decryptedValue = JSON.parse(decryptedValue);
        }
        return { [key.replace('_cipher_bundle', '')]: decryptedValue };
      } else {
        const value = await recursivelyDecrypt(item[key], decrypt);
        if (value) return { [key]: value };
      }
    });
    const allResolved = await Promise.all(all).catch((e) => {
      // throw e;
      console.error('error', e);
    });
    // @ts-ignore
    return Object.assign({}, ...allResolved);
  }

  return item;
}


export async function decrypt(cipherBundle: CipherBundle, getKey: KeyGetter): Promise<string> {
  if (!cipherBundle?.oaseId) {
    console.error('Oase: oaseId is required in bundle for decryption');
    return "";
  }
  const resolvedKey = await getKey(cipherBundle);
  if (!resolvedKey) {
    console.error('Oase: Could not resolve encryption key');
    return "";
  }

  return systemDecrypt(cipherBundle, resolvedKey);
}

export const useEncryption = (oaseId?: string): ReturnType => {
  const getKey = useEncryptionKeys();

  const encrypt = useCallback<Encrypter>(async (clearText, providedOaseId) => {
    const usingOaseId = providedOaseId || oaseId;
    if (!usingOaseId) {
      throw new Error('oaseId is required. Give it as argument to useEncryption(oaseId)');
    }

    const resolvedKey = await getKey({ oaseId: usingOaseId, kid: "current" });
    if (!resolvedKey?.importedKey) {
      throw new Error('Oase: Could not resolve encryption key');
    }

    let dataBuffer = Buffer.from(clearText, 'utf8');

    let iv = window.crypto.getRandomValues(new Uint8Array(12));

    const cipher = await window.crypto.subtle.encrypt(
      {
        name: "AES-GCM",
        iv: iv
      },
      resolvedKey.importedKey,
      dataBuffer //ArrayBuffer of data you want to encrypt
    );

    const cipherBase64 = Buffer.from(cipher).toString('base64');
    const ivBase64 = Buffer.from(iv).toString('base64');

    return {
      oaseId: usingOaseId,
      alg: 'AES-GCM',
      kid: resolvedKey.kid,
      ivBase64,
      cipherBase64
    }
  }, [oaseId]);

  const decryptHook = useCallback<Decrypter>(async (cipherBundle) => {
    return decrypt(cipherBundle, getKey);
  }, [getKey]);

  return { encrypt, decrypt: decryptHook };
}
