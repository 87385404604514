import { Platform } from 'react-native';
import { useCallback, useContext, memo } from 'react';
import { View, Pressable } from 'app/native'
import useTranslation from 'app/hooks/useTranslation'
import confirm from 'app/lib/confirm';
import useMainframe from 'app/hooks/useMainframe'
import classnames from 'classnames'
import usePerson from 'app/hooks/usePerson'
import Haptics from 'app/lib/haptics'
import MessageInteractionContext from 'app/contexts/message-interaction';
import { Menu, Item } from 'app/components/Menu';
import { useRouter } from 'solito/router';

export default memo(({ oaseId, chatId }: { oaseId: string, chatId: string }) => {
  const { message, reset, setMode } = useContext(MessageInteractionContext);
  const { t } = useTranslation();
  const { del } = useMainframe();
  const { push } = useRouter();
  const person = usePerson();

  function deleteMessageFlow() {
    confirm(t('oase.interactWithEvent.delete.confirm.title') as string, t('oase.interactWithEvent.delete.confirm.message') as string, [
      {
        text: t('oase.interactWithEvent.delete.confirm.cancel') as string,
        onPress: () => { },
        style: 'cancel',
      },
      {
        text: t('oase.interactWithEvent.delete.confirm.ok') as string,
        onPress: () => {
          Haptics.warning();
          confirm(t('oase.interactWithEvent.delete.reconfirm.title') as string, t('oase.interactWithEvent.delete.reconfirm.message') as string, [
            {
              text: t('oase.interactWithEvent.delete.reconfirm.cancel') as string,
              onPress: () => { },
              style: 'cancel',
            },
            {
              text: t('oase.interactWithEvent.delete.reconfirm.ok') as string,
              onPress: () => {
                del(`v1/oases/${oaseId}/messaging/events/${message.current?.id}`).then(Haptics.success);
                reset();
              },
            },
          ], {});
        },
      },
    ], {});
  }

  const deleteMessage = useCallback(async () => {
    if (person?.ephemeral) {
      alert(t("oase.interactWithEvent.ephemeral"));
    } else {
      deleteMessageFlow();
    }
  }, [person, t]);

  const containerClasses = classnames("flex-1", {
    'h-screen': Platform.OS == "web",
  });

  return (
    <View key={message.current?.id} className={containerClasses}>
      <Pressable
        className="h-full flex-1 flex flex-col justify-end"
        onPress={reset}
      >
        <Menu>
          <Item
            first
            onPress={() => {push(`/oase/${oaseId}/events/${message.current?.id}/edit`); reset();}}
            text={t("oase.interactWithEvent.edit.link")}
          />
          <Item
            onPress={(event: any) => setMode("reacting", event, oaseId, chatId)}
            text={t("oase.interactWithEvent.react.link")}
          />
          <Item
            danger
            onPress={deleteMessage}
            text={t("oase.interactWithEvent.delete.link")}
          />
        </Menu>
      </Pressable >
    </View >
  );
});
