import { useState, useEffect, ReactNode } from 'react'
import { Platform } from 'react-native'
import UserContext, { UserType } from 'app/contexts/user'
import { useToken } from 'app/hooks/useToken'
import useMainframe from 'app/hooks/useMainframe'
import useTranslation from 'app/hooks/useTranslation'
import { usePush } from '../push'

type UserProviderProps = {
  children: ReactNode;
};

const UserProvider = ({ children }: UserProviderProps) => {
  const [accessToken, _setToken] = useToken();
  const [user, setUser] = useState<UserType>(null);
  const mainframe = useMainframe();
  const { t } = useTranslation();
  const [expoToken, deviceToken] = usePush();

  useEffect(() => {
    if (!accessToken) return setUser(null);

    mainframe.get('v1/iam/me').then((user) => {
      if (!user) alert(t('provider.user.fetchFailed') + "a");
      setUser(user);
    }).catch(() => {
      // We might get interrupted by a redirect when auth on web.
    });
  }, [accessToken]);

  useEffect(() => {
    if (!accessToken || !deviceToken) return;

    mainframe.post(`v1/notification/push`, { expo_token: expoToken, device_token: deviceToken, platform: Platform.OS })
  }, [accessToken, expoToken, deviceToken]);

  return user ? (
    <UserContext.Provider value={user}>
      {children}
    </UserContext.Provider>
  ) : null;
}

UserProvider.defaultProps = {
  options: {}
}

export default UserProvider
