import { ReactNode, useState, useEffect } from 'react'
import TokenContext from 'app/contexts/token'
import { getAccessToken } from 'app/lib/mainframeFetch'

type TokenProviderProps = {
  children: ReactNode;
};

const TokenProvider = ({ children }: TokenProviderProps) => {
  const [accessToken, setAccessToken] = useState<any>(null);

  useEffect(() => {
    if(accessToken) return;

    getAccessToken().then((token) => {
      setAccessToken(token);
    });
  }, [accessToken]);

  return accessToken ? (
    <TokenContext.Provider value={{token: accessToken, setToken: setAccessToken}}>
      {children}
    </TokenContext.Provider>
  ) : null;
}

TokenProvider.defaultProps = {
}

export default TokenProvider

