import { createContext } from 'react'

export type ImportedKey = {
  kid: string;
  importedKey: CryptoKey;
  base64: string;
} | undefined;

export type OaseKeys = {
  [kid: string]: Promise<ImportedKey> | undefined;
}

export type EncryptionKeysRepo = {
  [oaseId: string]: OaseKeys | undefined;
}

export default createContext<EncryptionKeysRepo>({})
