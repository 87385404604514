import { createContext } from 'react'
import Uppy, { UppyFile } from '@uppy/core'
import { ImagePickerAsset } from 'expo-image-picker';

export type FileId = string
export type FileAdder = (asset: ImagePickerAsset) => Promise<FileId | null>;
export type File = UppyFile

interface UploadContext {
  uppy: Uppy | null | undefined,
  addFile: FileAdder
}

export default createContext<UploadContext>({ uppy: null, addFile: () => Promise.resolve("") })
