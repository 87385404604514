import { MediaType, url as mediaUrl, mp4 as mediaMp4, width as mediaWidth, height as mediaHeight, guessFileType, mediaType } from 'app/lib/media';
import Video from 'app/components/Video';
import { Image } from 'app/native';
import { ImageContentFit } from 'expo-image';
import { useMemo } from 'react';

export default ({ media, src, height: wantedHeight, width: wantedWidth, alt, contentFit, className, autoPlay, muted }: { media?: MediaType, src?: string, width: number, height?: number, alt?: string, contentFit?: ImageContentFit, className?: string, autoPlay?: boolean, muted: boolean }) => {

  const type = useMemo(() => mediaType(media), [media]);

  const url = src || mediaUrl(media);
  const mp4 = src || mediaMp4(media);
  const width = mediaWidth(media);
  const height = mediaHeight(media);
  const weKnowWidthAndHeight = width && height;
  const size = wantedWidth;
  let showWidth = size;
  let showHeight = wantedHeight || size;
  if (weKnowWidthAndHeight && !wantedHeight) {
    if (width > height) {
      showWidth = size;
      showHeight = size * (height / width);
    } else {
      showHeight = size;
      showWidth = size * (width / height);
    }
  }

  const isGif = (type == "gif");
  const isVideo = src ? guessFileType(src) == "video" : !!mp4;
  const shouldPlay = typeof autoPlay == "boolean" ? autoPlay : isGif;

  return isVideo ? (
    <Video
      // @ts-ignore
      style={{
        width: showWidth,
        height: showHeight,
      }}
      playInBackground
      progressUpdateInterval={10000000}
      repeat
      muted={muted}
      paused={!shouldPlay}
      contentFit={contentFit}
      alt={alt || "Video"}
      resizeMode="cover"
      source={{ uri: mp4 }}
      className={className}
    />
  ) : (
    <Image
      contentPosition={"left"}
      alt={alt || "Billede"}
      src={url}
      contentFit={contentFit || (weKnowWidthAndHeight ? "contain" : "cover")}
      width={showWidth}
      height={showHeight}
      className={className}
    />
  );
}
