import { mainframeRoot } from 'app/lib/config'
import { get, set, clear } from 'app/store/secure-store'
import mutex from './mutex'
let accessToken: string | null = null;
let refreshPromise: any = null;

export const logout = async () => {
  accessToken = null;
  await set('refreshToken', null);
  await clear();
};

const fetchIdToken = () => fetch(mainframeRoot("oauth2/authorize"), { method: "POST" });

export const migrateAccount = (oldRefreshToken: string, newAccessToken: string) => fetch(
  mainframeRoot(`v1/iam/link?old_refresh_token=${oldRefreshToken}`),
  { method: "PUT", headers: { "Authorization": `Bearer ${newAccessToken}` } }
);

export const exchangeIdToken = (idToken: string) => fetch(
  mainframeRoot("oauth2/token"),
  { method: "POST", headers: { "Authorization": `Bearer ${idToken}` } }
);

const exchangeRefreshToken = (refreshToken: string) => fetch(
  mainframeRoot("oauth2/refresh"),
  { method: "POST", headers: { "Authorization": `Bearer ${refreshToken}` } }
);

export async function refreshAccessToken() {
  if (refreshPromise) {
    return refreshPromise;
  }

  return refreshPromise = new Promise(async (resolve, _reject) => {
    mutex("tokenRefresh", async () => {
      // The lock has been acquired.
      const currentRefreshToken = await get('refreshToken');
      let tokens: any = {};
      if (!currentRefreshToken) {
        const { id_token } = await fetchIdToken().then((res) => res.json());
        tokens = await exchangeIdToken(id_token).then((res) => res.json());
      } else {
        tokens = await exchangeRefreshToken(currentRefreshToken).then((res) => res.json());
      }

      if (tokens.error) {
        await logout();
        const { id_token } = await fetchIdToken().then((res) => res.json());
        tokens = await exchangeIdToken(id_token).then((res) => res.json());
      }
      await set('refreshToken', tokens.refresh_token);
      accessToken = tokens.access_token;
      resolve(accessToken);
      refreshPromise = null;
      // Now the lock will be released.
    });
  });
}

async function doSignin(tokens: {access_token: string, refresh_token: string}, resolve: (value: string) => void) {
  await set('refreshToken', tokens.refresh_token);
  accessToken = tokens.access_token;
  resolve(accessToken);
}

export async function signIn(idToken: string) {
  return refreshPromise = new Promise(async (resolve, reject) => {
    const tokens = await exchangeIdToken(idToken).then((res) => res.json());
    const oldRefreshToken = await get('refreshToken');
    if (oldRefreshToken) {
      await migrateAccount(oldRefreshToken, tokens.access_token).then((response) => {
        if (response.ok) {
          // Account linked successfully.
          doSignin(tokens, resolve);
        } else {
          // Something went wrong when linking. Abort.
          reject("Linking failed");
        }
      }).catch(() => {
        // Something went wrong when linking. Abort.
        reject("Linking failed");
      });
    } else {
      doSignin(tokens, resolve);
    }
    refreshPromise = null;
  });
}

export async function getAccessToken() {
  if (accessToken) return Promise.resolve(accessToken);
  return refreshAccessToken();
}

async function customFetch(path: string, options: any) {
  const url = mainframeRoot(path);
  let token = await getAccessToken();
  options = options || {};
  options.headers = options.headers || {};
  options.headers.Authorization = `Bearer ${token}`;

  let response = await fetch(url, options);

  if (response.status === 401) {
    // Just try one more time.
    token = await refreshAccessToken();
    options.headers.Authorization = `Bearer ${token}`;

    response = await fetch(url, options);
  }

  if (response.status < 400) {
    return response;
  }
  return Promise.reject(response);
}

export default customFetch;

