import i18n from "i18next";
import { initReactI18next, useTranslation } from "react-i18next";
import 'intl-pluralrules'

import { locale } from 'expo-localization';
const lng = locale.split('-')[0];

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: {
      en: {
        common: {
          "messagePreview": {
            inReplyTo: "In reply to",
          },
          "relativeTime": {
            "yesterday": "Yesterday",
            bigDay: {
              today: "Today",
              yesterday: "Yesterday",
            },
          },
          "add_to_calendar": {
            link: "Add Oase to your calendar",
            instructions: "Add this link to your calendar app:",
            copy: "Press to copy",
            copied: "Copied!",
          },
          "settings": {
            "logged_in": "You are logged in",
            "logout": "Log out",
            "version": "App version: {{version}}",
            "delete": {
              "link": "Delete account",
              "warning": "If you delete your account, we will delete all your data. Including messages you have written in oases you have participated in. This is irrevesible. If you just want to leave a certain oase you can do so in the oase itself. Do you want to delete your account?",
              "go": "Yes, I want to completely delete my account",
              "nevermind": "No, I'll stay",
              "confirm": {
                "title": "Delete account?",
                "message": "Are you sure you want to delete your account?",
                "cancel": "Cancel",
                "ok": "Delete",
              }
            }
          },
          "landing": {
            "title": "Welcome to Oase",
            subtitle: "A chat-app giving your group a safe and private oasis in the digital dessert of distractions.",
            dream: "We dream of a world where humans understand the importance of relationships and togetherness, and we want the to be _the_ digital platform to support groups&mdash;without draining and distracting us.",
            cta_caption: "Get an oase today for your ",
            "communities": ["class", "friends group", "family", "supper club", "book club", "wine club", "mothers group", "volunteer board", "board game club", "running club"],
            "cta": "Enter your oase now",
            "more_than_a_group_chat": {
              "deeper": "Deeper connection",
              "title": "More than a group chat",
              "description": "Oase is built with everything your group needs to stay connected and organized, whether you're long-time friends, a freshly assembled team, a class, or a family spanning three generations."
            },
            "get_started": {
              "how_does_it_work": "How does it work?",
              "title": "Simple, above all",
              "description": "We've made an effort to make Oase simple to use. Here is what you do to get started: (Hint: If you're in the same room, it only takes a minute)",
              "one": {
                "title": "Log in",
                "description": "You have to login on Oase. We won't get your email, so don't worry about us spamming you. We couldn't even if we wanted."
              },
              "two": {
                "title": "Create your Oase.",
                "description": "One of you creates the Oase either here or in the app. That takes 2 seconds. We'll ask for a name for the Oase (and you don't even have to answer that)."
              },
              "three": {
                "title": "Invite members.",
                "description": "Get the others to scan the QR code for the Oase. They will be taken directly to the Oase. If you're not in the same room, you can send a link to your Oase just as easily."
              },
              "four": {
                "title": "Install our app (Optional)",
                "description": "It is possible to use Oase in your browser. But truth be told, it's just a better experience in the app."
              }
            },
            "testimonials": [
              {
                "quote": "Brilliant, after so long my family finally has a group chat.",
                "name": "Anonymous",
                "title": "Person using Oase",
              },
              {
                "quote": "I'm THIS close to being able to delete my Facebook account.",
                "name": "Anders Lemke-Holstein",
                "title": "Founder of Oase"
              }
            ],
            "features": {
              "oase": {
                "title": "The oase",
                "description": "The welcoming space for your group to stay up to date. Simple, secure, and designed for your community."
              },
              "images": {
                "title": "Image sharing",
                "description": "Images are at the heart of many communities. Share them in Oase with ease."
              },
              "polls": {
                "title": "Polls",
                "description": "A straightforward tool for quick feedback or decision-making, taking the pulse of your group effortlessly."
              },
              "one_on_one": {
                "title": "Private conversations",
                "description": "Sometimes, a sidebar is necessary. Have one-on-one chats with any group member, without leaving the app."
              },
              "events": {
                "title": "Events",
                "description": "Plan and keep track of upcoming events easily, ensuring everyone is on the same page for meetings, gatherings, or celebrations. Available soon."
              },
              "files": {
                "title": "Files (Coming soon)",
                "description": "A dedicated space for all your essential files, ensuring easy access and organization for everyone. Available soon."
              },
              "weshare": {
                "title": "Expenses",
                "description": "Keep track of expenses within your group, and get a clear overview of who owes what. Available soon."
              }
            },
            "values": {
              "title": "Our values",
              "description": "When crafting software, you're making a lot of decisions. 'How should this work?', 'What should this look like?', 'What should we prioritize?'. We're making a conscious effort to make sure all decisions are aligned with our core beliefs."
            },
            "points": {
              "connectionOverCollection": {
                "title": "Connection over collection",
                "description": "In a world obsessed with metrics and self-promotion, Oase brings the focus back to what matters: supporting your community."
              },
              "safety": {
                "title": "Safety over comfort",
                "description": "Oase will always prioritize the safety of marginalized people over the comfort of the privileged. Among other things, this means that you have to provide your name for each oase you participate in. This ensures, that you are in complete control of who knows what about you."
              },
              "transparent": {
                "title": "Transparency over profits",
                "description": "We're still working out the specifics of the business model, but one thing's for sure: our revenue will come from enhancing your experience, not exploiting your data."
              }
            },
            "second_title": "Built for you.\nAvailable today.",
            "second_cta": "Start your oase for free"
          },

          "hub": {
            "header": "Your oases",
            "new": "Create oase",
            "oase_card": {
              "placeholder": "Our oase 🌱"
            },
            "we_have_an_app": "Did you know we have an app?",
          },
          "create_oase": {
            title: "Settle into your new oase",
            description: "Let's get you started with your new oase.",
            optional: "Optional",
            oaseName: "Oase name",
            oaseNamePlaceholder: "Write oase name here...",
            oaseNameDescription: "If you're not sure what to call it, don't worry. You can always name it later.",
            yourName: "Your name",
            yourNamePlaceholder: "Write your name here...",
            yourNameDescription: "The name you provide, will only be used in this oase. You can always change it.",
            conditions: "Everything you do at Oase is private. We don't track you, and we don't sell your data. See our [privacy policy](https://oase.app/privacy). By creating an oase you accept our [terms and conditions](https://oase.app/terms). ",
            placeholder: "Oase name",
            submit: "Enter your new oase",
          },
          "provider": {
            "user": {
              "fetchFailed": "Something went wrong. Please restart the app and try again."
            }
          },
          "login": {
            "error": "Something went wrong. Please try again.",
            "login": "Log in",
            "logout": {
              "confirm": {
                "title": "Log out?",
                "message": "Are you sure you want to log out?",
                "cancel": "Cancel",
                "ok": "Log out"
              },
            }
          },
          "polls": {
            "tab": {
              "unanswered": "Unanswered",
              "all": "All",
            },
            "no_unanswered": {
              title: "No unanswered polls",
              create: "Add a poll by pressing the big plus.",
            },
            "create": {
              "title": "Add a poll",
              "explain": "Add a poll that everybody in {{name}} can participate in.",
              "poll_title": "Title",
              "options": "Options",
              "add_option": "Add option",
              "submit": "Add poll",
              "when_submit": "When you create a poll, everybody in {{name}} will be notified and they will be able to participate in the poll",
            }
          },
          "events": {
            "update": {
              title: "Update event",
              explain: "Update an event that everybody in {{name}} can see.",
              submit: "Update event",
              when_submit: "When you update an event, everybody in {{name}} will be able to see the updated event.",
            },
            "empty": {
              title: "This is where you will find your events",
              explain: "Doing stuff together is what this is all about. Create events in your oase to keep everybody informed about your important dates.",
              cta: "Create an event by pressing the big plus.",
            },
            "create": {
              "title": "Add an event",
              "explain": "Add an event that everybody in {{name}} can see.",
              "event_title": "Title",
              "show_in_ical_feeds": "Show in iCal feeds",
              "shown_in_ical_as": "Shown as",
              "explain_ical": "Each user on Oase has a personal iCal feed. This feed can be used to import events into your personal calendar. If you want this event to be included in the iCal feeds of the oase members, you should be aware, that Oase has to keep the event title, location and description unencrypted. Usually all data is encrypted before being sent to the Oase servers, but this wont be possible if the event details should be shown in the calendars of oase members.",
              "event_start": "Starts",
              "event_end": "Ends",
              "add_time": "+ Add time",
              "remove_time": "Remove time",
              "add_end": "+ Add end date",
              "add_location": "+ Add location",
              "add_description": "+ Add description",
              "remove_end": "Remove",
              "event_location": "Location",
              "event_description": "Description",
              "submit": "Add event",
              "when_submit": "When you create an event, everybody in {{name}} will be notified and they will be able to see the event.",
            }
          },
          "expenses": {
            "settlement": {
              message: "{{from}} transfered {{amount}} to {{to}}.",
              preview: {
                byline: "{{name}} transfered money",
              },
            },
            preview: {
              byline: "Expense for {{name}}",
            },
            "show": {
              expense: "Had an expense",
              addedAt: "Added",
            },
            balance: {
              title: {
                owe: "You owe",
                owed: "You are owed",
                even: "You are even",
              },
              showPaymentPlan: "Show settlement plan",
            },
            settle: {
              title: {
                owe: "You owe",
                owed: "You are owed",
                even: "You are even",
              },
              header: {
                toYou: "Settlements to you",
                fromYou: "Settlements from you",
              },
              button: {
                "toMe": "Recieved",
                "fromMe": "Paid",
              },
              unknownParticipant: "Unknown participant",
              confirm: {
                cancel: "No",
                ok: "Yes",
                toMe: {
                  title: "Settle with {{name}}",
                  message: "Did you recieve {{amount}} from {{name}}?",
                },
                fromMe: {
                  title: "Settle with {{name}}",
                  message: "Did you transfer {{amount}} to {{name}}?",
                }
              },
            },
            "update": {
              title: "Update expense",
              explain: "Update an expense that everybody in {{name}} can see.",
              submit: "Update expense",
              when_submit: "When you update an expense, everybody in {{name}} will be able to see the updated expense.",
            },
            "empty": {
              title: "This is where you will find your expenses",
              explain: "Doing stuff together is what this is all about. And when you do stuff, sometimes you have expenses that you need to share.",
              cta: "Add and expense by pressing the big plus.",
            },
            "create": {
              "amount": "Amount",
              "amount_placeholder": "Amount...",
              errors: {
                moreThanOneDecimal: "Please only use one decimal seperator.",
                moreThanTwoDecimals: "Please only use two decimals.",
                notANumber: "Please enter a number.",
                fixedAmountTooHigh: "The fixed amount is too high.",
                participantIsBothExcludedAndFixed: "A participant is both excluded and has a fixed amount.",
                noRemainingParticipantsToShareExpense: "There are no remaining participants to share the expense.",
                negativeAmount: "An amount is negative.",
              },
              "title": "Add an expense",
              "explain": "Add an expense that everybody in {{name}} can see.",
              "add_description": "+ Add description",
              "description": "Description",
              "participants": "Distribution",
              "submit": "Add expense",
              "when_submit": "When you create an expense, everybody in {{name}} will be able to see it.",
            }
          },
          "oase": {
            "name_placeholder": "Our oase 🌱",
            "new": {
              placeholder: "Oase name...",
            },
            "status": {
              title: "Oase status",
              limited: {
                title: "Limited",
                description: "Up to 10 members and no uploads of images or videos.",
                explain: "Running Oase costs money. To ensure transparency and align our incentives with yours, we rely on contributions from those who choose to give Oase to others, be it in groups they're part of or outside their direct involvement.\n\nWe offer a free, __limited__ version of Oase too. To keep our operational costs manageable, this version has a cap on the number of members and restricts the upload of files like images and videos.",
                cta: "Sponsor oase now",
                cta_explain: "Sponsoring an oase removes all limits and enables uploads of images and videos. Sponsors can also choose to empower the oase members to unlock other oases they participate in.",
              },
            },
            "settings": {
              title: "Oase settings",
              explain: "This is where you customize your oase. Everybody in the oase can see and change the settings of your oase.",
              name: {
                label: "Oase name",
                saved: "Oase name saved",
                placeholder: "Write name here...",
                explain: "This is the name of your oase. It will be visible to everybody in the oase.",
              }
            },
            "message_list": {
              "since_last_time": "Since last time",
              "new_reactions_one": "new reaction",
              "new_reactions_other": "new reactions",
              "updated_event": "updated event",
              "get_more": "Get more",
              "no_messages": "We're ready for the first message",
              "todays_events": "Today",
              "upcoming_events": "Upcoming events",
              "past_events": "Past events",
            },
            "gifPicker": {
              "search": "Search for GIFs (Powered By GIPHY)",
            },
            "event": {
              "byline": "Event added by {{name}}",
              "missing_title": "Event",
              "added_by": "Added by {{name}}",
            },
            "poll": {
              "byline": "Poll by {{name}} · {{time}}",
              "vote_count": "{{count}} of {{total}} has voted",
              "add_option": "Add option",
              "add_answer": "Please provide an answer",
              "new_options_since_last": "New options",
              "im_happy_with_my_votes": "I have nothing to add",
              "i_dont_want_to_vote": "I don't want to vote",
            },
            interactWithMessage: {
              react: {
                link: "Add reaction"
              },
              copy: {
                link: "Copy message"
              },
              delete: {
                link: "Delete message",
                confirm: {
                  title: "Delete message?",
                  message: "Are you sure you want to delete this message?",
                  cancel: "Cancel",
                  ok: "Delete",
                },
                reconfirm: {
                  title: "Please confirm that you want to delete this message?",
                  message: "Are you sure you want to delete this message?",
                  cancel: "Cancel",
                  ok: "Delete",
                },
              }
            },
            interactWithEvent: {
              edit: {
                link: "Edit event",
              },
              react: {
                link: "Add reaction",
              },
              delete: {
                link: "Delete event",
                confirm: {
                  title: "Delete event?",
                  message: "Are you sure you want to delete this event?",
                  cancel: "Cancel",
                  ok: "Delete",
                },
                reconfirm: {
                  title: "Please confirm that you want to delete this event?",
                  message: "Are you sure you want to delete this event?",
                  cancel: "Cancel",
                  ok: "Delete",
                },
              }
            },
            interactWithExpense: {
              edit: {
                link: "Edit expense",
              },
              react: {
                link: "Add reaction",
              },
              delete: {
                link: "Delete expense",
                confirm: {
                  title: "Delete expense?",
                  message: "Are you sure you want to delete this expense?",
                  cancel: "Cancel",
                  ok: "Delete",
                },
                reconfirm: {
                  title: "Please confirm that you want to delete this expense?",
                  message: "Are you sure you want to delete this expense?",
                  cancel: "Cancel",
                  ok: "Delete",
                },
              }
            },
            interactWithSettlement: {
              react: {
                link: "Add reaction",
              },
              delete: {
                link: "Delete settlement",
                confirm: {
                  title: "Delete settlement?",
                  message: "Are you sure you want to delete this settlement?",
                  cancel: "Cancel",
                  ok: "Delete",
                },
                reconfirm: {
                  title: "Please confirm that you want to delete this settlement?",
                  message: "Are you sure you want to delete this settlement?",
                  cancel: "Cancel",
                  ok: "Delete",
                },
              }
            },
            interactWithPoll: {
              react: {
                link: "Add reaction",
              },
              delete: {
                link: "Delete poll",
                confirm: {
                  title: "Delete poll?",
                  message: "Are you sure you want to delete this poll?",
                  cancel: "Cancel",
                  ok: "Delete",
                },
                reconfirm: {
                  title: "Please confirm that you want to delete this poll?",
                  message: "Are you sure you want to delete this poll?",
                  cancel: "Cancel",
                  ok: "Delete",
                },
              }
            },
            message: {
              "missingName": "New member",
              "noAuthor": "Removed participant",
              "responses_one": "1 reply",
              "responses_other": "{{count}} replies",
              "repliesBeforeThis_one": "{{count}} previous reply",
              "repliesBeforeThis_other": "{{count}} previous replies",
              "repliesAfterThis_one": "{{count}} more reply",
              "repliesAfterThis_other": "{{count}} more replies",
            },
            feel: {
              placeholder: "this oase",
              title: "The oase",
              danger: "Danger zone",
              explain: "All members of __{{name}}__ can see and change these settings.",
            },
            you: {
              title: "Your profile",
              "explain": "The information you provide here, will __only__ be used in {{name}}. This allows you to control your identity independently in each oase you participate in.",
              "name": {
                "saved": "Your name is updated",
                "label": "Your name",
                "explain": "The name you provide here, will __only__ be used in {{name}}",
                "placeholder": "Write your name..."
              },
              "image": {
                "title": "Your image",
                "add": "Upload your image",
                "remove": "Remove image",
                "change": "Change image",
                "participant": {
                  "ephemeral": "You must be logged in to change your image",
                }
              },
            },
            add_to_oase: {
              comingSoon: "(Coming soon)",
              event: "Add event",
              poll: "Add poll",
              expense: "Add expense",
              gif: "Send GIF",
              invite: "Invite people",
            },
            leave: {
              confirm: {
                title: "Leave {{name}}?",
                message: "When you leave {{name}} you wont be able to participate anymore. This is irrevesible.",
                cancel: "Cancel",
                ok: "Got it"
              },
              reconfirm: {
                title: "Please confirm that you want to leave {{name}}?",
                message: "Are you sure you want to leave {{name}}?",
                cancel: "Cancel",
                ok: "Leave"
              },
              button: "Leave oase",
              success: "You successfully left {{name}}.",
              placeholder: "this oase",
            },
            "participantCard": {
              "you": "(You)",
              "participant_name_placeholder": "New member",
              "last_activity_at": "Last seen {{time}}",
              remove: {
                confirm: {
                  title: "Remove {{participantName}} from {{oaseName}}?",
                  message: "If you remove {{participantName}} from {{oaseName}}, they will no longer be able to participate. This is irrevesible.",
                  cancel: "Cancel",
                  ok: "Got it"
                },
                reconfirm: {
                  title: "Please confirm that you want to remove {{participantName}} from {{oaseName}}?",
                  message: "Are you sure you want to remove {{participantName}} from {{oaseName}}?",
                  cancel: "Cancel",
                  ok: "Remove"
                },
              }
            },
            tab: {
              feed: "Our oase",
              polls: "Polls",
              events: "Events",
              expenses: "Expenses",
              participants: "Messages",
              profile: "Your profile",
              settings: "Oase settings",
              "hub_title": "Go back to the hub",
            },
            "color": {
              "choose": "Choose the color for {{name}}",
              "explain": "The color you choose here will be used throughout your oase create the mood you like.",
            },
            "reaction": {
              "custom": "Your reaction:",
              "ephemeral": "You must be logged in to react",
              "help": "You can use your keyboard to make a reaction. Both emojis and text are supported.",
              "placeholder": "Type or choose your reaction",
              "respond": "Reply",
            },
            "join": {
              "header": "Ready to join",
              "description": "You have been invited to join an oase. If you want to participate, just press the button below. You can always leave the oase again.",
              "oase_name_placeholder": "Our oase 🌱",
              "button": "Yes, please!",
              "no": "No, thanks. Take me to the hub",
              conditions: "Everything you do at Oase is private. We don't track you, and we don't sell your data. See our [privacy policy](https://oase.app/privacy). By joining an oase you accept our [terms and conditions](https://oase.app/terms). ",
              about: {
                "header": "What is Oase?",
                "description": "Oase is a new digital tool created to support your community. It's where you can communicate and organise your work.",
              },
              secure: {
                "header": "What we care about?",
                "description": "We believe that communities thrives best when they can focus on the things they care about. So Oase is built on a platform that is designed to help communities while protecting their data and privacy.",
              }
            },
            "sponsor": {
              title: "Activate this oase",
              description: "To unlock all features of this oase, you need to activate it. This is done by paying a small monthly fee. You can choose to pay yourself, or you can ask someone else to pay for you.",
              same_room: "Are you in the same room as the person who should pay?",
              qr_caption: "Show the following QR code to the person who should pay.",
              not_in_the_same_room: "Are you not in the same room as the person who should pay?",
              share_caption: "Share the following link with the person who should pay.",
              copy: "Copy link",
              share: "Share link",
              copied: "Link copied",
              what_happens: "What happens when you show the QR code or share the link?",
              here_is_how: "Here is how it works:",
              steps: [
                {
                  title: "They scan the QR code or click the link",
                  description: "This will take them to a page where they can create a sponsor and add payment information."
                },
                {
                  title: "They choose a subscription",
                  description: "You can choose between two subscription. One that activates this oase, and one that both activates this and empowers its members to activate other oases they participate in."
                },
                {
                  title: "They pay for the subscription",
                  description: ""
                },
                {
                  title: "The oase is activated",
                  description: "You can now use all features of the oase, and if the sponsor chose to, you can also activate other oases you participate in."
                },
              ]
            },
            "invite": {
              title: "Invite members",
              "same_room": "Are you in the same room?",
              "qr_caption": "Show this QR-code to invite people to join",
              "other_oase": "Someone from another oase?",
              "oase_name_placeholder": "this oase",
              "not_in_the_same_room": "Not in the same room?",
              "share_caption": "Send this link to the people you want to invite",
              "share": "Share link to join",
              "copy": "Copy link to join",
              "copied": "Link copied to clipboard",
              what_happens: "What happens when I invite someone?",
              "here_is_how": "Here is what happens after you show the person the QR-code or send them the link:",
              "steps": [
                {
                  "title": "They scan the QR or click the link",
                  "description": "They will see page explaining they are invited to participate in the oase."
                },
                {
                  "title": "They log in",
                  "description": "You have to login on Oase. Don't worry, though. We wont get your e-mails, so we wont be able to spam you with anything."
                },
                {
                  "title": "They can choose their name (optional)",
                  "description": "Before joining they can provide a name. They can also not, and provide it later. It's up to them."
                },
                {
                  "title": "That's it!",
                  "description": "If they decided to join the oase, now they can participate in your oase."
                },
                {
                  "title": "They can download our app (optional)",
                  "description": "If they want, they can download our app. It's not required, though. They can also just use the web version."
                },
              ],
              contact_book: {
                search_placeholder: "Press here to search your other oases...",
                search_placeholder_active: "Search for people to invite...",
                no_results: "No results",
                no_contacts: "You are only part of this oase, so you can't invite people from other oases yet.",
                invite: "Send message",
                message_we_will_send: "Message:",
                default_message: "Hi! I'm inviting you to join {{oaseName}}.",
                confirm: {
                  title: "Invite {{name}} to {{oaseName}}?",
                  message: "When inviting we will send a message in your name to {{name}} in {{otherOaseName}}. They will then be able to join {{oaseName}}.\n\nPress OK to send the message.",
                  "cancel": "Cancel",
                  "ok": "OK",
                },
                invited: "Message sent",
              }
            },
            "participants": {
              title: "People",
              edit: "Edit",
            },
            "onboarding": {
              "created": {
                title: "Your oase is ready",
                dont_worry: "But you're still alone. It's time to invite people.",
                invite: "Invite people",
                dismiss: "I'll explore first",
                description: "Show this QR-code to let others in to your oase.",
              },
              "joined": {
                title: "You are now a part of",
                description: "Settle in by following the guides.",
                dismiss: "Let's go!",
              },
              "invite": "Invite others by pressing here",
              "createOase": "Start a new oase by pressing here",
              "name": "Choose a name for your oase",
              "participantName": "Choose your name in this oase",
              "write": "Now write your first message",
              "login": "Now is a good time to log in",
              "login_description": "You don't have to login to use Oase, but it will allow you to use all features, and get access to your oases on other devices.",
              loginWall: {
                create: {
                  title: "Before you start an oase...",
                  description: "We will have to ask you to login before you start an oase. But don't worry, we wont get your e-mail.",
                },
                join: {
                  title: "Welcome to Oase",
                  description: "You have been invited to join an oase. Before letting you in, we have to ask you to log in. Don't worry, we wont get your e-mail.",
                },
                sponsor: {
                  title: "Before you sponsor oases...",
                  description: "In order to give subscriptions to oases, you will have to login. Don't worry, we wont get your e-mail.",
                },
                disclaimer: "Oase uses Promise for login. Among other things, that means that we don't get your e-mail address, and we can't (and wont) track or spam you.",
                cta: "Log ind",
              },
            },
            "screen": {
              "input": {
                "placeholder": "Aa",
                "sendInstructions": "Shift+Enter to send",
                "imagepicker": {
                  "missing_permission": {
                    "title": "Missing permission",
                    "message": "You need to give Oase permission to use your photos in settings.",
                    "cancel": "Cancel"
                  }
                }
              },
              "header": {
                "new": "Our oase 🌱",
                "back": "Back",
              }
            }
          }
        }
      },
      da: {
        common: {
          "messagePreview": {
            inReplyTo: "Som svar til",
          },
          "relativeTime": {
            "yesterday": "I går",
            bigDay: {
              today: "I dag",
              yesterday: "I går",
            },
          },
          "settings": {
            "version": "App version: {{version}}",
            "logged_in": "Du er logget ind",
            "logout": "Log ud",
            "delete": {
              "link": "Slet konto",
              "warning": "Hvis du sletter din konto vil alt hvad du har foretaget dig på Oase blive slettet. Inklusiv beskeder. Hvis du blot ønsker at forlade nogle oaser kan du gøre det på de enkelte oaser. Vil du slette din konto?",
              "go": "Ja, jeg vil slette min konto",
              "nevermind": "Nej, jeg bliver",
              "confirm": {
                "title": "Er du sikker på at du vil slette din konto?",
                "message": "Hvis du sletter din konto vil alt hvad du har foretaget dig på Oase blive slettet. Inklusiv beskeder",
                "cancel": "Annuller",
                "ok": "Slet"
              }
            },
          },
          "landing": {
            "title": "Velkommen til Oase",
            subtitle: "En chat-app som giver jer en tryg og privat oase i en ørken af distraktioner.",
            "communities": ["klasse", "vennegruppe", "familie", "madklub", "bogklub", "mødregruppe", "frivillige bestyrelse", "brætspilklub", "løbeklub", "fællesskab"],
            "dream": "Vi drømmer om en verden, hvor mennesker forstår hvor vigtige relationer og samvær er, og vi vil være _den_ digitale platform, som understøtter fælleskaberne&mdash;uden at dræne og distrahere os.",
            cta_caption: "Få en oase i dag til jeres",
            cta: "Start jeres oase nu",
            "more_than_a_group_chat": {
              deeper: "Dybere forbindelse",
              title: "Mere end en gruppechat",
              description: "Oase har alt hvad jeres fællesskab har brug for for at holde jer forbundet og organiseret. Lige meget hvem I er, vil I føle jer hjemme i en oase.",
            },
            get_started: {
              how_does_it_work: "Hvordan virker det?",
              title: "Simpelt, først og fremmest",
              description: "Vi har gjort os umage for, at det er nemt at bruge Oase. Her er hvad I skal gøre for at komme igang: (Hint: Hvis I er i samme rum, tager det kun et øjeblik)",
              one: {
                title: "Log ind",
                description: "Man skal logge ind på Oase bl.a. for at kunne bruge Oase både på sin telefon og på sin computer. Vi får ikke din e-mail, så du skal ikke frygte at vi tracker eller spammer dig. Det ville vi ikke kunne, selv hvis vi ville.",
              },
              two: {
                title: "Opret jeres oase.",
                description: "En af jer opretter en ny oase. Enten i app'en eller i en browser. Det tager 2 sekunder. Vi spørger kun til et navn til oasen (og det behøver du ikke engang svare på).",
              },
              three: {
                title: "Invitér medlemmer.",
                description: "Få de andre til at scanne oasens QR-kode. Så kommer de direkte ind i jeres oase. Hvis I ikke er i samme rum, kan du sende dem et link til oasen. Det er også nemt.",
              },
              four: {
                title: "Installér vores app (Valgfrit)",
                description: "I kan godt bruge Oase i en browser, men den bedste oplevelse får man altså i vores app.",
              },

            },
            testimonials: [
              {
                quote: "Genialt, efter lang tid har hele min familie en gruppe chat sammen.",
                name: "Anonym",
                title: "Person der bruger Oase",
              },
              {
                quote: "Jeg er SÅ tæt på at kunne slette min Facebook-konto.",
                name: "Anders Lemke-Holstein",
                title: "Stifter af Oase",
              },
            ],
            "features": {
              "oase": {
                title: "Jeres oase",
                description: "Der hvor det hele er samlet og her hvor I skriver sammen. Et trygt sted for jeres fællesskab. Enkelt, sikkert og designet til jer.",
              },
              "images": {
                title: "Billeder",
                description: "Billeder er hjertet i mange fællesskaber. Del dem nemt i jeres oase og brug dem som omdrejningspunkt for samtaler.",
              },
              "polls": {
                title: "Afstemninger",
                description: "Et enkelt værktøj til at træffe beslutninger hvor alle bliver hørt. Kan også bruges til at finde datoer for begivenheder.",
              },
              "one_on_one": {
                title: "Private samtaler",
                description: "Nogle gange er en-til-en samtaler nødvendige. Tal privat med hinanden uden at forlade appen.",
              },
              "events": {
                title: "Begivenheder",
                description: "Hold styr på jeres begivenheder så alle er på samme side.",
              },
              "files": {
                title: "Filer (Kommer snart)",
                description: "Et sikkert sted til alle jeres vigtige filer, så alle nemt kan finde dem. Kommer snart.",
              },
              "weshare": {
                title: "Udgiftsdeling",
                description: "Del nemt jeres udgifter og hold styr på hvem der skylder hvem hvad.",
              },
            },
            "values": {
              title: "Vores værdier",
              description: 'Når man udvikler og skaber software træffer man mange beslutninger hele tiden. "Hvordan skal det her virke?". "Hvordan skal det her se ud?". "Hvad skal vi prioritere?". På Oase gør vi os umage for, at alle disse små og store beslutninger altid stemmer overens med vores grundlæggende værdier.',
            },
            points: {
              "safety": {
                title: "Tryghed frem for komfort",
                description: "Vi vil altid prioritere sikkerhed og tryghed frem for komfort. Det vil for eksempel sige, at du skal skrive dit navn for hver oase du deltager i. Det sikrer, at det er dig der styrer, hvem der ved hvad om dig.",
              },
              "connectionOverCollection": {
                title: "Fælleskabet over individet",
                description: "I en verden som i højere og højere grad fokuserer på hvordan man kan promovere sig selv, retter Oase fokus mod det som faktisk betyder noget: at understøtte jeres fællesskab.",
              },
              "transparent": {
                title: "Gennemskuelighed over fortjeneste",
                description: "Vi har endnu ikke lagt os fast på en forretningsmodel. Men én ting er sikkert. Vores indkomst skal komme fra at forbedre jeres oplevelse af at være en del af et fællesskab. Vi skal ikke sælge jeres data.",
              },
            },
            second_title: "Bygget til jer.\nKlar nu.",
            second_cta: "Start jeres oase gratis",
          },
          "hub": {
            "header": "Dine oaser",
            "new": "Opret oase",
            "oase_card": {
              "placeholder": "Vores oase 🌱"
            },
            "we_have_an_app": "Vidste du at, vi har en app?",
          },
          "create_oase": {
            title: "Kom på plads i jeres nye oase",
            description: "Lad os starte med at få styr på hvad oasen skal hedde, og hvad du skal hedde i denne oase.",
            oaseName: "Oasens navn",
            optional: "Valgfrit",
            oaseNamePlaceholder: "Skriv oasens navn her...",
            oaseNameDescription: "Hvis du ikke er helt sikker på hvad du skal kalde jeres oase, er det helt ok. I kan altid give den et navn.",
            yourName: "Dit navn",
            yourNamePlaceholder: "Skriv dit navn her...",
            yourNameDescription: "Det navn du skriver her, vil udelukkende blive brugt i denne oase. Du kan altid ændre det.",
            conditions: "Alt hvad I deler på Oase er privat og krypteret. Vi hverken tracker jer eller sælger jeres data. Se evt. vores [privatlivspolitik](https://oase.app/privacy). Når I opretter en oase, accepterer I samtidig vores [brugerbetingelser](https://oase.app/terms). ",
            placeholder: "Oase name",
            submit: "Gå ind i jeres nye oase",
          },
          "provider": {
            "user": {
              "fetchFailed": "Der gik noget galt da vi forsøgte at hente dine oplysninger. Luk appen og prøv igen."
            }
          },
          "login": {
            "error": "Der gik noget galt da vi forsøgte at logge dig ind. Prøv igen.",
            "login": "Log ind",
            "logout": {
              "confirm": {
                "title": "Log ud?",
                "message": "Du vil ikke længere kunne se dine oaser, før du logger ind igen.",
                "cancel": "Annuller",
                "ok": "Log ud"
              },
            }
          },
          "polls": {
            "tab": {
              "unanswered": "Ubesvarede",
              "all": "Alle",
            },
            "no_unanswered": {
              title: "Ingen ubesvarede afstemninger",
              create: "Opret en afstemning ved at trykke på det store plus.",
            },
            "create": {
              "title": "Opret afstemning",
              "explain": "Lav afstemning som alle i {{name}} kan deltage i.",
              "poll_title": "Titel",
              "options": "Svarmuligheder",
              "add_option": "Tilføj svarmulighed",
              "submit": "Opret afstemning",
              "when_submit": "Når du opretter en afstemning får alle i {{name}} en notifikation om det og de får derefter mulighed for at deltage i afstemingen.",
            }
          },
          "events": {
            "update": {
              title: "Opdater begivenhed",
              explain: "Opdater begivenheden som alle i {{name}} kan se.",
              submit: "Opdater begivenhed",
              when_submit: "Når du opdaterer en begivenhed kan alle i {{name}} se ændringen af begivenheden i deres kalender.",
            },
            "empty": {
              title: "Her kan I finde jeres begivenheder",
              explain: "Det vigtigste er at lave noget sammen. Opret begivenheder i jeres oase for at holde alle informeret.",
              cta: "Opret den første begivenhed ved at trykke på det store plus.",
            },
            "create": {
              "title": "Opret begivenhed",
              "explain": "Opret en begivenhed som alle i {{name}} kan se.",
              "event_title": "Titel",
              "show_in_ical_feeds": "Vis i medlemmers iCal feed",
              "shown_in_ical_as": "Vises som",
              "explain_ical": "Hver bruger på Oase har sit private kalender-feed som de kan tilføje til sin private kalender. For at vise denne begivenhed i jeres private kalendre, er Oase nødt til at gemme begivenhedens titel, lokation og beskrivelse og jeres oases navn i et læsbart format. Normalt bliver data krypteret inden det sendes til Oase's servere, hvilket ikke vil gøre sig gældende i dette tilfælde.",
              "event_start": "Starter",
              "event_end": "Slutter",
              "add_time": "+ Tilføj tidspunkt",
              "remove_time": "Fjern tidspunkt",
              "add_end": "+ Tilføj slutdato",
              "add_location": "+ Tilføj lokation",
              "add_description": "+ Tilføj beskrivelse",
              "remove_end": "Fjern",
              "event_location": "Lokation",
              "event_description": "Beskrivelse",
              "submit": "Opret begivenhed",
              "when_submit": "Når du opretter en begivenhed får alle i {{name}} en notifikation om det og de kan se begivenheden i kalenderen.",
            }
          },
          "expenses": {
            "settlement": {
              message: "{{from}} overførte {{amount}} til {{to}}.",
              preview: {
                byline: "{{name}} overførte penge",
              },
            },
            preview: {
              byline: "Udgift af {{name}}",
            },
            "show": {
              expense: "Har haft en udgift",
              addedAt: "Tilføjet",
            },
            balance: {
              title: {
                owe: "Du skylder",
                owed: "Dit tilgodehavende",
                even: "Der er balance i regnskabet",
              },
              showPaymentPlan: "Vis betalingsplan",
            },
            settle: {
              title: {
                owe: "Du skylder",
                owed: "Du har tilgodehavende på",
                even: "Der er balance i regnskabet",
              },
              header: {
                toYou: "Overførsler til dig",
                fromYou: "Overførsler fra dig",
              },
              button: {
                "toMe": "Modtaget",
                "fromMe": "Betalt",
              },
              unknownParticipant: "Ukendt medlem",
              confirm: {
                cancel: "Nej",
                ok: "Ja",
                toMe: {
                  title: "Betaling fra {{name}}",
                  message: "Har du modtaget {{amount}} fra {{name}}?",
                },
                fromMe: {
                  title: "Betal til {{name}}",
                  message: "Har du overført {{amount}} til {{name}}?",
                }
              },
            },
            "update": {
              title: "Opdatér udgift",
              explain: "Opdatér udgiften som alle i {{name}} kan se.",
              submit: "Opdatér udgift",
              when_submit: "Når du opdatérer en udgift kan alle i {{name}} se ændringen af udgiften.",
            },
            "empty": {
              title: "Her kan I finde jeres udgifter",
              explain: "Det vigtigste er at lave noget sammen. Opret udgifter i jeres oase for at holde styr på hvem der har udlæg.",
              cta: "Opret den første udgift ved at trykke på det store plus.",
            },
            "create": {
              "amount": "Beløb",
              "amount_placeholder": "Beløb...",
              errors: {
                moreThanOneDecimal: "Brug kun enten komma eller punktom som decimal.",
                moreThanTwoDecimals: "Angiv kun to decimaler.",
                notANumber: "Angiv kun tal.",
                fixedAmountTooHigh: "Beløbet overstiger udgiften.",
                participantIsBothExcludedAndFixed: "Et medlem er både udeladt og har et fast beløb angivet.",
                noRemainingParticipantsToShareExpense: "Der er ikke nogle medlemmer tilbage som kan dele resten af udgiften.",
                negativeAmount: "Brug kun positive tal.",
              },
              "title": "Tilføj en udgift",
              "explain": "Alle i {{name}} kan se de udgifter der oprettes.",
              "description": "Beskrivelse",
              "participants": "Fordeling",
              "submit": "Tilføj udgift",
              "when_submit": "Når du tilføjer udgiften vil alle i {{name}} kunne se den.",
            }
          },
          "oase": {
            "name_placeholder": "Vores oase 🌱",
            "new": {
              placeholder: "Oasens navn...",
              myNamePlaceholder: "Dit navn...",
            },
            "settings": {
              title: "Indstillinger for oasen",
              explain: "Her kan I sætte jeres præg på jeres oase. Alle i oasen har adgang til at se og ændre indstillingerne.",
              name: {
                label: "Oasens navn",
                saved: "Oasens navn er gemt",
                placeholder: "Skriv oasens navn...",
                explain: "Alle oasens deltagere kan se og ændre oasens navn.",
              }
            },
            "message_list": {
              "since_last_time": "Siden sidst",
              "new_reactions_one": "ny reaktion",
              "new_reactions_other": "nye reaktioner",
              "updated_event": "opdateret begivenhed",
              "get_more": "Hent mere",
              "no_messages": "Her er klar til den første besked",
              "todays_events": "I dag",
              "upcoming_events": "Kommende begivenheder",
              "past_events": "Tidligere begivenheder",
            },
            "gifPicker": {
              "search": "Søg efter GIF (Powered By GIPHY)",
            },
            "event": {
              "missing_title": "Begivenhed",
              "added_by": "Tilføjet af {{name}}",
              "byline": "Begivenhed tilføjet af {{name}}",
            },
            "poll": {
              "byline": "Afstemning af {{name}} · {{time}}",
              "vote_count": "{{count}} ud af {{total}} har stemt",
              "add_option": "Tilføj svarmulighed",
              "add_answer": "Vi mangler din stemme",
              "new_options_since_last": "Der er kommet nye svarmuligtheder",
              "im_happy_with_my_votes": "Jeg har ikke noget at tilføje",
              "i_dont_want_to_vote": "Jeg vil ikke stemme",
            },
            interactWithMessage: {
              react: {
                link: "Tilføj reaktion"
              },
              copy: {
                link: "Kopier besked"
              },
              delete: {
                link: "Slet besked",
                confirm: {
                  title: "Slet besked?",
                  message: "Er du sikker på at du vil slette denne besked?",
                  cancel: "Annullér",
                  ok: "Slet besked",
                },
                reconfirm: {
                  title: "Bekræft sletning",
                  message: "Er du sikker på at du vil slette denne besked? Denne handling kan ikke fortrydes.",
                  cancel: "Annullér",
                  ok: "Slet besked",
                },
              }
            },
            interactWithEvent: {
              edit: {
                link: "Rediger begivenhed",
              },
              react: {
                link: "Tilføj reaktion",
              },
              delete: {
                link: "Slet begivenhed",
                confirm: {
                  title: "Slet begivenhed?",
                  message: "Er du sikker på at du vil slette denne begivenhed?",
                  cancel: "Annullér",
                  ok: "Slet begivenhed",
                },
                reconfirm: {
                  title: "Bekræft sletning",
                  message: "Er du sikker på at du vil slette denne begivenhed? Denne handling kan ikke fortrydes.",
                  cancel: "Annullér",
                  ok: "Slet begivenhed",
                },
              }
            },
            interactWithExpense: {
              edit: {
                link: "Rediger udgift",
              },
              react: {
                link: "Tilføj reaktion",
              },
              delete: {
                link: "Slet udgift",
                confirm: {
                  title: "Slet udgift?",
                  message: "Er du sikker på at du vil slette denne udgift?",
                  cancel: "Annullér",
                  ok: "Slet udgift",
                },
                reconfirm: {
                  title: "Bekræft sletning",
                  message: "Er du sikker på at du vil slette denne udgift? Denne handling kan ikke fortrydes.",
                  cancel: "Annullér",
                  ok: "Slet udgift",
                },
              }
            },
            interactWithSettlement: {
              react: {
                link: "Tilføj reaktion",
              },
              delete: {
                link: "Slet betaling",
                confirm: {
                  title: "Slet betaling?",
                  message: "Er du sikker på at du vil slette denne betaling?",
                  cancel: "Annullér",
                  ok: "Slet betaling",
                },
                reconfirm: {
                  title: "Bekræft betaling",
                  message: "Er du sikker på at du vil slette denne betaling? Denne handling kan ikke fortrydes.",
                  cancel: "Annullér",
                  ok: "Slet betaling",
                },
              }
            },
            interactWithPoll: {
              react: {
                link: "Tilføj reaktion",
              },
              delete: {
                link: "Slet afstemning",
                confirm: {
                  title: "Slet afstemning?",
                  message: "Er du sikker på at du vil slette denne afstemning?",
                  cancel: "Annullér",
                  ok: "Slet afstemning",
                },
                reconfirm: {
                  title: "Bekræft sletning",
                  message: "Er du sikker på at du vil slette denne afstemning? Denne handling kan ikke fortrydes.",
                  cancel: "Annullér",
                  ok: "Slet afstemning",
                },
              }
            },
            message: {
              "missingName": "Nyt medlem",
              "noAuthor": "Slettet medlem",
              "responses_one": "1 svar",
              "responses_other": "{{count}} svar",
              "repliesBeforeThis_one": "{{count}} tidligere svar",
              "repliesBeforeThis_other": "{{count}} tidligere svar",
              "repliesAfterThis_one": "{{count}} efterfølgende svar",
              "repliesAfterThis_other": "{{count}} efterfølgende svar",
            },
            feel: {
              placeholder: "denne oase",
              title: "Oasen",
              danger: "Farezone",
              explain: "Alle medlemmer af __{{name}}__ kan se og ændre disse indstillinger.",
            },
            you: {
              title: "Din profil",
              "explain": "Information du angiver her, vil udelukkende blive brugt i __{{name}}__. Du kan dermed have forskellige profiler i forskellige oaser.",
              "name": {
                "saved": "Dit navn er gemt",
                "label": "Dit navn",
                "explain": "Det navn du angiver her, vil udelukkende blive brugt i __{{name}}__",
                "placeholder": "Skriv dit navn her..."
              },
              "image": {
                "title": "Dit billede",
                "add": "Upload billede",
                "remove": "Fjern billede",
                "change": "Skift billede",
                "participant": {
                  "ephemeral": "Du skal være logget ind for at ændre dit billede",
                }
              },
            },
            add_to_oase: {
              event: "Tilføj begivenhed",
              poll: "Tilføj afstemning",
              expense: "Tilføj udgift",
              gif: "Send GIF",
              invite: "Tilføj medlem",
            },
            leave: {
              confirm: {
                title: "Forlad {{name}}?",
                message: "Når du forlader {{name}} vil du ikke længere kunne deltage i oasen. Dette kan ikke fortrydes.",
                cancel: "Annulér",
                ok: "Forstået"
              },
              reconfirm: {
                title: "Bekræft venligst at du ønsker at forlade {{name}}",
                message: "Er du sikker på at du vil forlade {{name}}?",
                cancel: "Annulér",
                ok: "Forlad"
              },
              button: "Forlad oase",
              success: "Du har forladt {{name}}.",
              placeholder: "denne oase",
            },
            "participantCard": {
              "you": "(Dig)",
              "participant_name_placeholder": "Nyt medlem",
              "last_activity_at": "Sidst set {{time}}",
              remove: {
                confirm: {
                  title: "Fjern {{participantName}} fra {{oaseName}}?",
                  message: "Hvis du fjerner {{participantName}} vil vedkommende ikke længere kunne deltage i oasen. Dette kan ikke fortrydes.",
                  cancel: "Annulér",
                  ok: "Forstået"
                },
                reconfirm: {
                  title: "Bekræft venligst at du ønsker at fjerne {{participantName}} fra {{oaseName}}",
                  message: "Er du sikker på at du vil fjerne {{participantName}}?",
                  cancel: "Annulér",
                  ok: "Fjern"
                },
              }
            },
            tab: {
              feed: "Vores oase",
              events: "Kalender",
              expenses: "Udgifter",
              polls: "Afstemninger",
              participants: "Beskeder",
              profile: "Din profil",
              settings: "Indstillinger",
              "hub_title": "Gå tilbage til oversigten",
            },
            "color": {
              "choose": "Vælg en farve til {{name}}",
              "explain": "Den farve I vælger her, vil blive brugt udvalgte steder i oasen til at give jeres oase den stemning I ønsker.",
            },
            "reaction": {
              "custom": "Din reaktion:",
              "ephemeral": "Du skal være logget ind for at reagere",
              "respond": "Svar",
              "placeholder": "Skriv eller vælg din reaktion",
              "help": "Du kan bruge dit keyboard til at vælge en reaktion. Både emojis og tekst virker."
            },
            "join": {
              "header": "Vil du være med i",
              "description": "Du er blevet inviteret til at deltage i en oase. Hvis du godt vil deltage, skal du trykke på knappen. Du kan altid forlade oasen igen.",
              "oase_name_placeholder": "Vores oase 🌱",
              "button": "Ja tak!",
              "no": "Nej, tak. Gå til forsiden",
              conditions: "Alt hvad du deler på Oase er privat og krypteret. Vi hverken tracker jer eller sælger jeres data. Se evt. vores [privatlivspolitik](https://oase.app/privacy). Når du  deltager i en oase, accepterer du samtidig vores [brugerbetingelser](https://oase.app/terms).",
              about: {
                "header": "Hvad er Oase?",
                "description": "Oase er et nyt digitalt værktøj som er skabt til at understøtte jeres fællesskab. Det er her I kan kommunikere og organisere jeres arbejde.",
              },
              secure: {
                "header": "Hvad vi tror på?",
                "description": "Vi tror på at et fællesskab er stærkest når det er sikkert og privat. Derfor er Oase bygget på en platform som er skabt til at beskytte jeres data og privatliv.",
              }
            },
            "sponsor": {
              title: "Aktivér denne oase",
              description: "For at fjerne alle begrænsninger og aktivere alle funktioner, skal oasen aktiveres. Det sker ved at en af jer, eller en tredjepart, opretter sig som sponsor og køber et abonnement til oasen. Det er nemt og foregår via en browser.",
              same_room: "Er dem som skal betale i samme rum som dig?",
              qr_caption: "Vis denne QR-kode til dem som skal betale.",
              not_in_the_same_room: "Er dem som skal betale ikke i samme rum som dig?",
              share_caption: "Send følgende link til dem som skal betale.",
              copy: "Kopier link",
              share: "Del link",
              copied: "Link kopieret",
              what_happens: "Hvordan betaler man?",
              here_is_how: "Når du har vist QR-koden eller sendt linket sker der følgende for vedkommende der skal betale:",
              steps: [
                {
                  title: "De scanner QR-koden eller åbner linket",
                  description: "De får nu vist en side hvor de kan oprette sig som sponsor og indtaste betalingsoplysninger."
                },
                {
                  title: "De vælger abonnementstype",
                  description: "Man kan vælge mellem to slags abonnement. Et som bare aktiverer denne oase, og et som også giver deltagerne af denne oase mulighed for at aktivere andre oaser."
                },
                {
                  title: "De betaler for det valgte abonnement",
                  description: ""
                },
                {
                  title: "Oasen bliver aktiveret",
                  description: "I kan nu bruge alle funktioner i oasen."
                },
              ]
            },
            "invite": {
              "title": "Invitér medlemmer",
              "same_room": "Er I samme rum?",
              "qr_caption": "Vis denne QR-kode til dem du vil invitere.",
              "other_oase": "Er det én fra en anden oase?",
              "share_caption": "Send følgende link til dem du vil invitere.",
              "oase_name_placeholder": "denne oase",
              "not_in_the_same_room": "Ikke i samme rum?",
              "share": "Del link",
              "copy": "Kopier link",
              "copied": "Link kopieret",
              what_happens: "Hvad sker der når jeg inviterer andre?",
              "here_is_how": "Når du har vist QR-koden eller sendt linket til sker der følgende for vedkommende du har inviteret:",
              "steps": [
                {
                  "title": "De skanner QR-koden eller klikker på linket",
                  "description": "De får nu vist en side hvor de bliver fortalt at de er inviteret til at deltage i oasen."
                },
                {
                  "title": "De logger ind",
                  "description": "Man skal logge ind på Oase bl.a. for at kunne tilgå sine oaser på flere devices. Og bare rolig, Oase får IKKE jeres e-mails. Så vi kommer ikke til at spamme jer med alt muligt. Det kan vi slet ikke."
                },
                {
                  "title": "De vælger deres navn (valgfrit)",
                  "description": "På siden kan de indtaste deres navn hvis de har lyst."
                },
                {
                  "title": "Det var det!",
                  "description": "Hvis de vil, kan de nu deltage i oasen."
                },
                {
                  "title": "De kan downloade vores app (valgfrit)",
                  "description": "Hvis de vil have en bedre oplevelse, kan de downloade appen. Den er gratis og findes til både iPhone og Android. Dog skal man være logget ind for at få adgang til sine oaser."
                },
              ],
              contact_book: {
                search_placeholder_active: "Søg efter dem du vil invitere...",
                search_placeholder: "Tryk her for at søge i dine andre oaser...",
                no_results: "Ingen resultater",
                no_contacts: "Du er ikke i andre oaser endnu, så vi kan ikke finde nogen at invitere.",
                invite: "Send besked",
                message_we_will_send: "Besked:",
                default_message: "Hej! Jeg vil gerne invitere dig til at være med i {{oaseName}}.",
                confirm: {
                  title: "Invitér {{name}} til at være med i {{oaseName}}?",
                  message: "Vi sender en besked i dit navn til {{name}} i {{otherOaseName}}. Når de får beskeden vil de få mulighed for at deltage i {{oaseName}}.\n\nTryk på OK for at sende beskeden.",
                  "cancel": "Annulér",
                  "ok": "OK",
                },
                invited: "Besked sendt",
              }
            },
            "participants": {
              title: "Medlemmer",
              edit: "Redigér",
            },
            "onboarding": {
              "created": {
                title: "Jeres oase er klar",
                dont_worry: "Men du er stadig alene herinde.",
                description: "Vis denne QR-code for at få andre ind i oasen.",
                dismiss: "Jeg udforsker lige først",
                invite: "Invitér de andre",
              },
              "joined": {
                title: "Du er nu en del af",
                description: "Kom godt på plads ved at følge guiden på skærmen.",
                dismiss: "Lad os komme igang",
              },
              "invite": "Inviter andre ved at trykke her",
              "name": "Vælg navn til oasen",
              "participantName": "Vælg dit navn i denne oase",
              "createOase": "Start en ny oase ved at trykke her",
              "write": "Skriv din første besked",
              "login": "Nu er et godt tidspunkt at logge ind",
              "login_description": "Du behøver ikke logge ind for at bruge Oase, men det giver dig adgang til flere funktioner, samt mulighed for at få adgang til dine oaser på flere forskellige devices (for eksempel både din computer og din telefon).",
              loginWall: {
                create: {
                  title: "Inden du starter en oase...",
                  description: "Vi skal lige have dig til at logge ind, inden du starter en oase. Det er gratis og vi får ikke din e-mail adresse.",
                },
                join: {
                  title: "Velkommen til Oase",
                  description: "Du er blevet inviteret til at deltage i en oase, men inden vi lukker dig ind i den, skal vi lige have dig til at logge ind. Det er gratis og vi får ikke din e-mail adresse.",
                },
                disclaimer: "Oase bruger Promise til login. Det betyder bl.a. at vi ikke får din e-mail og dermed ikke kan (eller vil) tracke eller spamme dig.",
                cta: "Log ind",
              },
            },
            "screen": {
              "input": {
                "placeholder": "Aa",
                "sendInstructions": "Shift+Enter for at sende",
                "imagepicker": {
                  "missing_permission": {
                    "title": "Vi mangler tilladelse",
                    "message": "Du skal give tilladelse at Oase må bruge dine billeder i indstillinger.",
                    "cancel": "Annuller",
                  }
                }
              },
              "header": {
                "new": "Vores oase 🌱",
                "back": "Tilbage",
              }
            }
          }
        }

      }
    },
    lng: lng, // if you're using a language detector, do not define the lng option
    fallbackLng: "en",
    defaultNS: 'common',
    interpolation: {
      escapeValue: false // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    }
  });


export default useTranslation;
export { i18n };
