import { ComponentProps, ReactNode } from 'react'
import { TextStyle, Button as NativeButton, Pressable as NativePressable, TouchableHighlight as NativeTouchableHighlight, TouchableOpacity as NativeTouchableOpacity, TextInput as NativeTextInput, ScrollView as NativeScrollView, FlatList as NativeFlatList, Text as FullNativeText } from 'react-native'
import { TextLink as SolitoTextLink, Link as SolitoLink } from 'solito/link'
import { styled } from 'nativewind'
import NativeCheckbox from 'expo-checkbox';

import { NativeView, NativeText } from './native';
type WithChildren = { children?: ReactNode, style?: any, onLayout?: any } // Use this when using the NativeView and Text.

import { SolitoImageProps } from 'solito/build/image/image.types';
import { SolitoImage } from 'solito/image';
import { MotiLink as SolitoMotiLink, MotiLinkProps } from 'solito/moti'

export const View = styled<WithChildren>(NativeView)
export const ScrollView = styled(NativeScrollView)
export const Text = styled<WithChildren>(NativeText)
export const Button = styled(NativeButton)
export const Pressable = styled(NativePressable)
export const TouchableHighlight = styled(NativeTouchableHighlight)
export const TouchableOpacity = styled(NativeTouchableOpacity)
export const TextInput = styled(NativeTextInput)
export const FlatList = styled(NativeFlatList)
export const Image = styled<SolitoImageProps>(SolitoImage);
export const MotiLink = styled(SolitoMotiLink)
export const FullText = styled(FullNativeText)
export const Checkbox = styled(NativeCheckbox)


/**
 * Solito's TextLink doesn't work directly with styled() since it has a textProps prop
 * By wrapping it in a function, we can forward style down properly.
 */
export const TextLink = styled<
  ComponentProps<typeof SolitoTextLink> & { style?: TextStyle }
>(function TextLink({ style, textProps, ...props }: any) {
  return (
    <SolitoTextLink
      textProps={{ ...textProps, style: [style, textProps?.style] }}
      {...props}
    />
  )
}, '')

/**
 * Solito's Link doesn't work directly with styled() since it has a viewProps prop
 * By wrapping it in a function, we can forward style down properly.
 */
export const Link = styled<
  ComponentProps<typeof SolitoLink> & { style?: TextStyle }
>(function Link({ style, viewProps, ...props }: any) {
  return (
    <SolitoLink
      viewProps={{ ...viewProps, style: [style, viewProps?.style] }}
      {...props}
    />
  )
}, '')

export const ButtonLinkScale = ({ children, href, style, onPress, onLongPress }: { children: React.ReactNode, href: string, style: any, onPress?: () => void, onLongPress?: () => void }) => (
  <MotiLink
    onPress={onPress}
    href={href}
    onLongPress={onLongPress}
    hitSlop={{ top: 0, bottom: 0, left: 0, right: 0 }}
    style={style}
    transition={{
      type: 'timing',
      duration: 300,
    }}
    animate={({ hovered, pressed }) => {
      'worklet'

      return {
        scale: pressed ? 0.95 : 1,
        opacity: hovered ? 0.9 : 1,
      }
    }}
  >
    {children}
  </MotiLink>
)

export const ButtonLinkOpacity = ({ children, className, ...props }: MotiLinkProps & { children: React.ReactNode, className?: string }) => (
  <MotiLink
    {...props}
    className={className}
    animate={({ pressed }) => {
      'worklet'

      return {
        opacity: pressed ? 0.7 : 1,
      }
    }}
  >
    {children}
  </MotiLink>
)
