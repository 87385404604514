import { useRef } from 'react';
import EncryptionKeysContext, {EncryptionKeysRepo} from 'app/contexts/encryptionKeys';

export const EncryptionKeysProvider = ({ children }: {children: any}) => {
  const keyBank = useRef<EncryptionKeysRepo>({});

  return (
    <EncryptionKeysContext.Provider value={keyBank.current}>
      {children}
    </EncryptionKeysContext.Provider>
  );
};
