// @ts-check

const colors = {
  brand: {
    // primary: "#23856F",
    primary: "#4AC4A9",
    secondary: {
      yellow: "#F6e07e",
      blue: "#3b7bed",
      red: "#ec9470",
      teal: "#2d5f63",
      pink: "#f6c4b7",
      green: "#3fa59e"
    },
    black: {
      900: "#18343e",
      800: "#465d65",
      700: "#5e7178",
      600: "#74858b",
      500: "#8c9a9f",
      400: "#a3aeb2",
      300: "#bac2c5",
      200: "#d1d6d9",
      100: "#e8ebec",
      50: "#f9fafb",
    }
  },
}

const extend = {
  // that is animation class
  animation: {
    "fade-in": 'fadeIn 400ms ease-in-out',
  },

  // that is actual animation
  keyframes: () => ({
    fadeIn: {
      '0%': { opacity: 0 },
      '100%': { opacity: 1 },
    },
  }),
  colors: colors,
  fontFamily: {
    "sans": ['InterRegular', 'sans-serif'],

    "b-regular": ['GimletRegular', 'sans-serif'],
    "b-semi": ['GimletSemiBold', 'sans-serif'],
    "b-semibold": ['GimletSemiBold', 'sans-serif'],
    "b-bold": ['GimletBold', 'sans-serif'],
    "b-light": ['GimletLight', 'sans-serif'],
    "b-black": ['GimletBlack', 'sans-serif'],
    "b-medium": ['GimletMedium', 'sans-serif'],

    "t-regular": ['InterRegular', 'sans-serif'],
    "t-semi": ['InterSemiBold', 'sans-serif'],
    "t-semibold": ['InterSemiBold', 'sans-serif'],
    "t-bold": ['InterBold', 'sans-serif'],
    "t-light": ['InterLight', 'sans-serif'],
    "t-black": ['InterBlack', 'sans-serif'],
    "t-medium": ['InterMedium', 'sans-serif'],
    "regular": ['InterRegular', 'sans-serif'],
    "semi": ['InterSemiBold', 'sans-serif'],
    "semibold": ['InterSemiBold', 'sans-serif'],
    "bold": ['InterBold', 'sans-serif'],
    "light": ['InterLight', 'sans-serif'],
    "black": ['InterBlack', 'sans-serif'],
    "medium": ['InterMedium', 'sans-serif'],
  },
}

/** @type {import('tailwindcss').Config['theme']} */
const theme = {
  // edit your tailwind theme here!
  // https://tailwindcss.com/docs/adding-custom-styles
  extend: extend
}

const safelist = [
  {
    pattern: /(fill|stroke|bg|text|border)-(white|(brand-(primary|(black-(50|100|200|300|800|900))|(secondary-(yellow|blue|red|pink|teal|green)))))/,
  }
]

// @ts-ignore
module.exports = {
  colors,
  theme,
  safelist
}
