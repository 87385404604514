import { View, Text } from "app/native";
import { MessageType } from "./Message";
import { Participant } from 'app/features/oase/ParticipantAvatar';
import useProjection from 'app/hooks/useProjection';

export default ({message, oaseId}: {message: MessageType, oaseId: string}) => {
  const [participants] = useProjection(`participants:list:${oaseId}`);

  return (
    <View className="flex flex-col space-y-2">
      {message.reactions?.slice().sort((a, b) => a.reaction.localeCompare(b.reaction))?.map((reaction, index) => {
        const thisParticipant: Participant = participants?.collection?.find((p: any) => p.participant_id == reaction.participant_id);
        return <View key={`${reaction.reaction}${thisParticipant?.participant_id}${index}`} className="flex flex-row space-x-4 items-center">
          <Text className="text-base font-t-regular text-white min-w-[30px]">{reaction.reaction}</Text>
          <Text className="text-sm font-t-medium text-white">{thisParticipant?.participant_name}</Text>
        </View>
      })}
    </View>
  )
}
