import { applyMiddleware, configureStore } from '@reduxjs/toolkit'
import projectionsSlice from '../slices/projections'
import subscriptionsSlice from '../slices/subscriptions'
import * as uppyReduxStore from '@uppy/store-redux'

let enhancer = applyMiddleware(
  uppyReduxStore.middleware,
)

const store = configureStore({
  reducer: {
    projections: projectionsSlice,
    subscriptions: subscriptionsSlice,
    uppy: uppyReduxStore.reducer,
  },
  // enhancers: [enhancer],
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        // Ignore these action types
        ignoredActions: [uppyReduxStore.STATE_UPDATE],
        // Ignore these field paths in all actions
        ignoredActionPaths: ['payload.channel', 'payload.decrypt', 'meta.arg.decrypt', 'meta.arg.method'],
        // Ignore these paths in the state
        ignoredPaths: [],
      },
    }),
})

export default store
export type AppDispatch = typeof store.dispatch
